import type { GenericIssueField } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import type {
	CreateIssueParameters,
	ExtraCreateIssuePayload,
} from '@atlassian/jira-business-issue-create/src/types.tsx';
import { ASSIGNEE_TYPE, SUMMARY_TYPE } from '@atlassian/jira-platform-field-config';
import type { JWMBoardInlineCreateIssueVariables } from './__generated_apollo__/JWMBoardInlineCreateIssue';

const RANK_BEFORE_ID = 'rankBefore';
const RANK_AFTER_ID = 'rankAfter';

export const buildInput = (
	input: CreateIssueParameters['input'],
	extraPayload: ExtraCreateIssuePayload,
	categoryField: GenericIssueField | null,
): JWMBoardInlineCreateIssueVariables['input'] => {
	const singleLineTextFields = [
		{
			fieldId: SUMMARY_TYPE,
			text: input.summary,
		},
	];
	const singleSelectUserPickerFields = [];

	if (input.category && categoryField?.id) {
		singleLineTextFields.push({
			// @ts-expect-error - Type 'string' is not assignable to type '"summary"'.
			fieldId: categoryField.id,
			text: input.category.optionId,
		});
	}

	if (input.assignee) {
		singleSelectUserPickerFields.push({
			fieldId: ASSIGNEE_TYPE,
			user: {
				accountId: input.assignee.accountId,
			},
		});
	}

	const fields = {
		singleLineTextFields,
		singleSelectUserPickerFields,
		...(input.priority && {
			priority: {
				priorityId: input.priority.priorityId,
			},
		}),
		...(input.parentIssueId && {
			parentField: {
				issueId: input.parentIssueId,
			},
		}),
	};

	const rank = {
		...(input.rankBefore && {
			before: {
				id: RANK_BEFORE_ID,
				issueId: input.rankBefore.issueId,
			},
		}),
		...(input.rankAfter && {
			after: {
				id: RANK_AFTER_ID,
				issueId: input.rankAfter.issueId,
			},
		}),
	};

	const result = {
		projectId: input.projectId,
		issueType: input.issueType.id,
		fields,
		rank,
		...(extraPayload?.transitionId && { transitionId: extraPayload.transitionId }),
	};

	return result;
};
