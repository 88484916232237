import { createStore, createHook } from '@atlassian/react-sweet-state';
import actions from './actions';
import type { InlineFieldsActions, InlineFieldsState } from './types';

// Inline fields state tracks field states in create issue view as the user interacts with them
// this is particularly needed as we want to save the field's value when the user
// unfocuses the field
const store = createStore<InlineFieldsState, InlineFieldsActions>({
	name: 'forge.inline-fields-state',
	initialState: {},
	actions,
});

export const useInlineFields = createHook(store);

export const useInlineField = createHook(store, {
	selector: (state, fieldId: string) => state[fieldId],
});

export const useInlineFieldsActions = createHook(store, {
	selector: null,
});
