import noop from 'lodash/noop';
// eslint-disable-next-line jira/wrm/no-load-bridge
import { loadBridge } from '@atlassian/jira-common-bridge/src';
import getXsrfToken from '@atlassian/jira-platform-xsrf-token';
import type { IssueId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import { timeToRenderTransitionModal } from './transition-modal-performance-analytics';

type DialogSuccessCallback = (smartAjaxResultData: string) => void;
type DialogErrorCallback = (error?: unknown) => void;
type DialogCancelCallback = (event: Event, dialogElement: HTMLElement, reason: string) => void;

type Params = {
	issueId?: IssueId;
	issueKey?: IssueKey;
	issueKeys?: IssueKey[];
	transitionId: number | string;
};

export default class TransitionScreenLegacyDialog {
	issueId: string | undefined;

	issueKey: string | undefined;

	issueKeys: IssueKey[] | undefined;

	transitionId: number | string;

	onDialogSuccessCallback: DialogSuccessCallback = noop;

	onDialogErrorCallback: DialogErrorCallback = noop;

	onDialogCancelCallback: DialogCancelCallback = noop;

	constructor({ issueId, transitionId, issueKey, issueKeys }: Params) {
		this.issueId = issueId;
		this.transitionId = transitionId;
		this.issueKey = issueKey;
		this.issueKeys = issueKeys;
	}

	static onDialogContentReady = (): void => {
		// This event fired by a dialog before the dialog itself is rendered and visible to the user
		// That's why we need to postpone sending the response to the next moment after it became visible
		setTimeout(() => {
			timeToRenderTransitionModal.stop();
		});
	};

	onDialogSuccess(onDialogSuccessCallback: DialogSuccessCallback): this {
		this.onDialogSuccessCallback = onDialogSuccessCallback;
		return this;
	}

	onDialogError(onDialogErrorCallback: DialogErrorCallback): this {
		this.onDialogErrorCallback = onDialogErrorCallback;
		return this;
	}

	onDialogCancel(onDialogCancelCallback: DialogCancelCallback): this {
		this.onDialogCancelCallback = onDialogCancelCallback;
		return this;
	}

	getIssueParam() {
		if (this.issueId != null) {
			return `id=${this.issueId}`;
		}

		if (this.issueKey != null) {
			return `key=${this.issueKey}`;
		}

		return '';
	}

	getUrl() {
		const { issueKeys } = this;
		if (issueKeys != null) {
			// key= is needed to be sent together with keys= because of legacy API
			return `/secure/BulkTransitionIssue!default.jspa?keys=${issueKeys.join(',')}&key=${
				issueKeys[0]
			}&action=${this.transitionId}&atl_token=${
				getXsrfToken() || ''
			}&decorator=dialog&inline=true&returnUrl=`;
		}

		return `/secure/WorkflowUIDispatcher.jspa?${this.getIssueParam()}&action=${
			this.transitionId
		}&atl_token=${getXsrfToken() || ''}&returnUrl=`;
	}

	async show() {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener(
			'legacyJiraDialogContentReady',
			TransitionScreenLegacyDialog.onDialogContentReady,
		);
		// eslint-disable-next-line jira/wrm/no-load-bridge
		const FormDialog = await loadBridge({
			name: 'jira/dialog/form-dialog',
			wrmKeys: 'wrc!jira.webresources:dialogs',
		});

		// @ts-expect-error - TS2571 - Object is of type 'unknown'.
		const dialog = new FormDialog({
			id: 'edit-issue-dialog',
			ajaxOptions: {
				url: this.getUrl(),
				dataType: 'json',
			},
			autoClose: true,
			widthClass: 'large',
			onDialogFinished: this.onDialogSuccessCallback,
			onUnSuccessfulSubmit: this.onDialogErrorCallback,
			shouldLoadHeritageFormDialogContext: true,
		});

		const cancelNativeHandler = (event: Event) => {
			if (!(event instanceof CustomEvent)) {
				return;
			}
			const dialogElement = event.detail.popup;
			const { reason } = event.detail;

			if (dialogElement.is(dialog.get$popup())) {
				if (reason === 'cancel' || reason === 'esc') {
					this.onDialogCancelCallback(event, dialogElement, reason);
				}

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.removeEventListener('legacyJiraDialogBeforeHide', cancelNativeHandler);

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				document.removeEventListener(
					'legacyJiraDialogContentReady',
					TransitionScreenLegacyDialog.onDialogContentReady,
				);
			}
		};

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		document.addEventListener('legacyJiraDialogBeforeHide', cancelNativeHandler);

		dialog.show();
	}
}
