import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';
import {
	ASSIGNEE_TYPE,
	COMPONENTS_TYPE,
	DUE_DATE_TYPE,
	ISSUE_KEY_TYPE,
	ISSUE_TYPE,
	LABELS_TYPE,
	PARENT_TYPE,
	PRIORITY_TYPE,
	STATUS_TYPE,
	SUBTASKS_TYPE,
	SUMMARY_TYPE,
} from '@atlassian/jira-platform-field-config';

// JWM Board UI Constants, derived from board-kit constants
export const Z_INDEX = {
	collapsedStickyHeaders: 2,
	stickyHeaders: 4,
};

export const EMPTY_BOARD_HEIGHT = 200;
export const COLLAPSED_COLUMN_FIXED_WIDTH = 40;
export const COLUMN_FIXED_WIDTH = 270;
export const COLUMN_HEADER_HEIGHT = 48;
export const GAP_BETWEEN_COLUMNS = 12;
export const TRANSITION_ZONE_MIN_HEIGHT = 90;

export const CARD_DND_TYPE = 'CARD';
export const COLUMN_DND_TYPE = 'COLUMN';

// Both dueDate and duedate (camelCase, lowerCase) were added to the array
// because of the inconsistency between rest response and availableFields response.
// Board rest API response is in camelCase but
// availableFields return the type lowercase.
// Defining both here prevents extended data implementation from treating duedate as an extra field.
// Similar problem with issuetype.
export const BASE_FIELDS = [
	'key',
	'type',
	'dueDate',
	ISSUE_TYPE,
	SUMMARY_TYPE,
	STATUS_TYPE,
	ASSIGNEE_TYPE,
	DUE_DATE_TYPE,
	SUBTASKS_TYPE,
	PRIORITY_TYPE,
];

export const MAX_ISSUE_TYPES_COUNT = 25;

export const EMPTY_FILTER_VALUE = null;

export const VIEW_EXPERIENCE: ExperienceDetails = {
	experience: 'viewBoard',
	packageName: 'jiraWorkManagementBoard',
	teamName: 'wanjel',
};

export const CARD_ACTION_DELETE_EXPERIENCE: ExperienceDetails = {
	experience: 'cardActionDelete',
	experienceId: 'cardActionDelete',
	packageName: 'jiraWorkManagementBoard',
	teamName: 'wanjel',
};

export const LOAD_EXTENDED_BOARD_DATA_EXPERIENCE: ExperienceDetails = {
	experience: 'loadExtendedBoardData',
	experienceId: 'loadExtendedBoardData',
	packageName: 'jiraWorkManagementBoard',
	teamName: 'wanjel',
};

export const LOAD_SUBTASKS_EXPERIENCE: ExperienceDetails = {
	experience: 'loadSubtasks',
	experienceId: 'loadSubtasks',
	packageName: 'jiraWorkManagementBoard',
	teamName: 'wanjel',
};

export const COLUMN_ACTION_RENAME_EXPERIENCE: ExperienceDetails = {
	experience: 'columnActionRename',
	experienceId: 'columnActionRename',
	packageName: 'jiraWorkManagementBoard',
	teamName: 'wanjel',
};

export const COLUMN_ACTION_ADD_EXPERIENCE: ExperienceDetails = {
	experience: 'columnActionAdd',
	experienceId: 'columnActionAdd',
	packageName: 'jiraWorkManagementBoard',
	teamName: 'wanjel',
};

export const COLUMN_ACTION_DELETE_EXPERIENCE: ExperienceDetails = {
	experience: 'columnActionDelete',
	experienceId: 'columnActionDelete',
	packageName: 'jiraWorkManagementBoard',
	teamName: 'wanjel',
};

export const NULL_GROUP_KEY = 'null_pivot';
export const OTHER = 'other';

// Board Field Types
export const BOARD_FIELD_CATEGORY = 'BOARD_FIELD_CATEGORY';
export const BOARD_FIELD_COMPONENTS = 'BOARD_FIELD_COMPONENTS';
export const BOARD_FIELD_CONFLUENCE_PAGES_COUNT = 'BOARD_FIELD_CONFLUENCE_PAGES_COUNT';
export const BOARD_FIELD_DATE = 'BOARD_FIELD_DATE';
export const BOARD_FIELD_DATE_TIME = 'BOARD_FIELD_DATE_TIME';
export const BOARD_FIELD_FLOAT = 'BOARD_FIELD_FLOAT';
export const BOARD_FIELD_ISSUE_LINKS_COUNT = 'BOARD_FIELD_ISSUE_LINKS_COUNT';
export const BOARD_FIELD_ISSUE_TYPE = 'BOARD_FIELD_ISSUE_TYPE';
export const BOARD_FIELD_LABEL = 'BOARD_FIELD_LABEL';
export const BOARD_FIELD_MULTI_USER = 'BOARD_FIELD_MULTI_USER';
export const BOARD_FIELD_PARENT = 'BOARD_FIELD_PARENT';
export const BOARD_FIELD_PRIORITY = 'BOARD_FIELD_PRIORITY';
export const BOARD_FIELD_SELECT = 'BOARD_FIELD_SELECT';
export const BOARD_FIELD_STATUS = 'BOARD_FIELD_STATUS';
export const BOARD_FIELD_STORY_POINTS = 'BOARD_FIELD_STORY_POINTS';
export const BOARD_FIELD_TEXT = 'BOARD_FIELD_TEXT';
export const BOARD_FIELD_TIME_ORIGINAL_ESTIMATE = 'BOARD_FIELD_TIME_ORIGINAL_ESTIMATE';
export const BOARD_FIELD_USER = 'BOARD_FIELD_USER';

// Board Issue Fields
export const ASSIGNEE_ID = ASSIGNEE_TYPE;
export const CATEGORY_ID = 'category';
export const COMPONENT_ID = COMPONENTS_TYPE;
export const DUE_DATE_ID = DUE_DATE_TYPE;
export const ISSUE_KEY_ID = ISSUE_KEY_TYPE;
export const ISSUE_TYPE_ID = ISSUE_TYPE;
export const LABEL_ID = LABELS_TYPE;
export const PARENT_ID = PARENT_TYPE;
export const PRIORITY_ID = PRIORITY_TYPE;
export const RANK_ID = 'rank';
export const STATUS_ID = STATUS_TYPE;
export const SUMMARY_ID = SUMMARY_TYPE;

// Card Covers
export const CARD_COVER_MEDIA_READ_TOKEN_DURATION = 900;
export const CARD_COVER_MEDIA_READ_TOKEN_MAX_LENGTH = 65534;

// Temporary Status Id, will be overridden in the backend
export const TEMPORARY_STATUS_ID = 42;
