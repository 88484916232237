import { useCallback, useMemo } from 'react';
import {
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
	GROUP_BY_PRIORITY,
	GROUP_BY_STATUS,
} from '@atlassian/jira-business-constants';
import { useCategoryField } from '@atlassian/jira-business-entity-project/src/controllers/category-field/index.tsx';
import type { AdditionalCreateIssueContext } from '@atlassian/jira-business-issue-create/src/types.tsx';
import type { Props as InlineCreateProps } from '@atlassian/jira-business-issue-create/src/ui/index.tsx';
import { CARD_APPEARANCE } from '@atlassian/jira-business-issue-create/src/ui/inline-create-form/index.tsx';
import {
	SUMMARY_TYPE,
	STATUS_TYPE,
	ASSIGNEE_TYPE,
	PRIORITY_TYPE,
	ISSUE_TYPE,
	ISSUE_KEY_TYPE,
} from '@atlassian/jira-platform-field-config';
import {
	ASSIGNEE_ID,
	BOARD_FIELD_CATEGORY,
	BOARD_FIELD_ISSUE_TYPE,
	BOARD_FIELD_PRIORITY,
	BOARD_FIELD_STATUS,
	BOARD_FIELD_TEXT,
	BOARD_FIELD_USER,
	CATEGORY_ID,
	ISSUE_KEY_ID,
	ISSUE_TYPE_ID,
	NULL_GROUP_KEY,
	PRIORITY_ID,
	RANK_ID,
	STATUS_ID,
	SUMMARY_ID,
} from '../../common/constants';
import type { Group, BoardIssue } from '../../common/types';
import type { JWMBoardInlineCreateIssue_createJiraBusinessIssue_item as CreatedBoardItem } from '../../services/create-issue/__generated_apollo__/JWMBoardInlineCreateIssue.ts';
import { useTransformIssues } from '../../utils/issue-transformer';
import { useIssueStoreActions } from '../board-issue-store';
import { useFieldIds } from '../field-ids';
import { useWorkflowStoreState } from '../workflow-store';

type UseInlineCreate = {
	group: Group;
	onAddOptimisticIssue?: () => void;
	rankBeforeIssue?: BoardIssue;
};

export const useInlineCreateProps = ({
	group,
	onAddOptimisticIssue,
	rankBeforeIssue,
}: UseInlineCreate) => {
	const { data: categoryField } = useCategoryField();
	const categoryFieldId = categoryField?.id;
	const { setIssues, removeIssues } = useIssueStoreActions();
	const fieldIdsToReturn = useFieldIds();
	const transformToIssues = useTransformIssues();
	const { issueTypes } = useWorkflowStoreState();

	const allowedIssueTypeIds = useMemo(
		() => issueTypes.map((issueType) => issueType.id),
		[issueTypes],
	);

	const prepareIssueContext = useCallback(() => {
		const issueContext: AdditionalCreateIssueContext = {};

		if (rankBeforeIssue) {
			issueContext.rankBefore = {
				issueId: String(rankBeforeIssue.id),
				rank: rankBeforeIssue.fields[RANK_ID].value,
			};
		}

		if (group.id === NULL_GROUP_KEY) {
			return issueContext;
		}

		switch (group.type) {
			case GROUP_BY_ASSIGNEE:
				issueContext.assignee = {
					accountId: group.id,
					avatarUrl: group.imageUrl ?? '',
					displayName: group.name,
				};
				break;
			case GROUP_BY_PRIORITY:
				issueContext.priority = {
					iconUrl: group.imageUrl ?? '',
					name: group.name,
					priorityId: group.id,
				};
				break;
			case GROUP_BY_CATEGORY:
				issueContext.category = {
					color: group.color || '',
					optionId: group.id,
					value: group.name,
				};
				break;
			case GROUP_BY_STATUS:
				issueContext.status = {
					name: group.name,
					statusCategoryId: group.statusCategoryId,
					statusId: group.id,
				};
				break;
			default:
				break;
		}

		return issueContext;
	}, [group, rankBeforeIssue]);

	const handleSubmit: InlineCreateProps<CreatedBoardItem>['onSubmit'] = useCallback(
		async ({ optimisticIssuePayload, promise }) => {
			const optimisticIssue: BoardIssue = {
				id: optimisticIssuePayload.issueId,
				coverMedia: null,
				fields: {
					[ISSUE_TYPE_ID]: {
						id: optimisticIssuePayload.issueType.id,
						fieldId: ISSUE_TYPE,
						type: BOARD_FIELD_ISSUE_TYPE,
						issueType: {
							id: optimisticIssuePayload.issueType.id,
							name: optimisticIssuePayload.issueType.name,
							iconUrl: optimisticIssuePayload.issueType.avatarUrl ?? '',
							hierarchyLevel: optimisticIssuePayload.issueType.hierarchyLevel,
						},
					},
					[ISSUE_KEY_ID]: {
						fieldId: ISSUE_KEY_TYPE,
						type: BOARD_FIELD_TEXT,
						value: '',
					},
					[STATUS_ID]: {
						fieldId: STATUS_TYPE,
						status: {
							id: optimisticIssuePayload.status?.statusId ?? '',
							name: optimisticIssuePayload.status?.name ?? '',
							statusCategoryId: optimisticIssuePayload.status?.statusCategoryId ?? 2,
						},
						type: BOARD_FIELD_STATUS,
						value: optimisticIssuePayload.status?.statusId ?? '0',
					},
					[SUMMARY_ID]: {
						fieldId: SUMMARY_TYPE,
						type: BOARD_FIELD_TEXT,
						value: optimisticIssuePayload.summary,
					},
					[RANK_ID]: {
						fieldId: '',
						type: BOARD_FIELD_TEXT,
						value: optimisticIssuePayload.rank ?? 'z',
					},
				},
				subtaskMetadata: {
					done: 0,
					total: 0,
				},
			};

			if (optimisticIssuePayload.assignee) {
				optimisticIssue.fields[ASSIGNEE_ID] = {
					fieldId: ASSIGNEE_TYPE,
					type: BOARD_FIELD_USER,
					value: optimisticIssuePayload.assignee.accountId,
					user: {
						accountId: optimisticIssuePayload.assignee.accountId,
						avatarURL: optimisticIssuePayload.assignee.avatarUrl,
						name: optimisticIssuePayload.assignee.displayName,
					},
				};
			}

			if (optimisticIssuePayload.priority) {
				optimisticIssue.fields[PRIORITY_ID] = {
					fieldId: PRIORITY_TYPE,
					type: BOARD_FIELD_PRIORITY,
					value: optimisticIssuePayload.priority.priorityId,
					priority: {
						id: optimisticIssuePayload.priority.priorityId,
						iconUrl: optimisticIssuePayload.priority.iconUrl,
						name: optimisticIssuePayload.priority.name,
					},
				};
			}

			if (optimisticIssuePayload.category && categoryFieldId) {
				optimisticIssue.fields[CATEGORY_ID] = {
					fieldId: categoryFieldId,
					type: BOARD_FIELD_CATEGORY,
					value: optimisticIssuePayload.category.optionId,
					category: {
						color: optimisticIssuePayload.category.color,
						name: group.name,
					},
				};
			}

			setIssues([optimisticIssue]);

			onAddOptimisticIssue?.();

			let createdBoardItem: CreatedBoardItem | null | undefined;

			try {
				createdBoardItem = await promise;
			} catch (e) {
				// swallow error
			}

			if (createdBoardItem) {
				const issues = transformToIssues([createdBoardItem]);

				setIssues(issues);
			}

			removeIssues([optimisticIssue.id]);
		},
		[categoryFieldId, setIssues, onAddOptimisticIssue, removeIssues, group.name, transformToIssues],
	);

	return {
		allowedIssueTypeIds,
		appearance: CARD_APPEARANCE,
		fieldIdsToReturn,
		onPrepareIssueContext: prepareIssueContext,
		onSubmit: handleSubmit,
	};
};
