import React, { useCallback, useRef } from 'react';
import ShareIcon from '@atlaskit/icon/glyph/share';
import { useEntityAri } from '@atlassian/jira-business-entity-common/src/controllers/ari-context/index.tsx';
import { ThemedButton } from '@atlassian/jira-business-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 as MessageDescriptor } from '@atlassian/jira-intl/src/v2/types.tsx';
import ShareButton from '@atlassian/jira-share-button';
import { useShareIntegrations } from '@atlassian/jira-share-integrations';
import { AsyncShareProjectNudge } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/jwm-share-project/async.tsx';
import messages from './messages';

const SHARE_ICON = <ShareIcon label="" size="small" />;

type Props = {
	isAutoOpenDialog?: boolean;
	shareTitle: MessageDescriptor;
	integrationType: string;
	shareContentType: string;
	onDialogClose?: () => void;
};

export const JWMShareButton = ({
	shareTitle,
	integrationType,
	shareContentType,
	isAutoOpenDialog = false,
	onDialogClose,
}: Props) => {
	const { formatMessage } = useIntl();
	const { shareIntegrations } = useShareIntegrations(shareContentType);
	const entityAri = useEntityAri();

	const shareButtonRef = useRef<HTMLElement>(null);

	const renderCustomTriggerButton = useCallback(
		// @ts-expect-error - TS7031 - Binding element 'onClick' implicitly has an 'any' type. | TS7031 - Binding element 'isSelected' implicitly has an 'any' type. | TS7031 - Binding element 'ref' implicitly has an 'any' type.
		({ onClick, isSelected }, { ref, ...triggerProps }) => (
			<div ref={ref}>
				<ThemedButton
					{...triggerProps}
					ref={shareButtonRef}
					onClick={onClick}
					isSelected={isSelected}
					iconBefore={SHARE_ICON}
					appearance="subtle"
				>
					{formatMessage(messages.share)}
				</ThemedButton>
			</div>
		),
		[formatMessage],
	);

	const handleDialogClose = () => {
		onDialogClose?.();
		shareButtonRef?.current?.focus();
	};

	return (
		<AsyncShareProjectNudge>
			<ShareButton
				isAutoOpenDialog={isAutoOpenDialog}
				integrationType={integrationType}
				triggerButtonStyle="icon-with-text"
				productId="jira"
				subProductId="jira-core"
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				shareLink={__SERVER__ ? '' : window.location.href}
				shareTitle={formatMessage(shareTitle)}
				shareContentType={shareContentType}
				// @ts-expect-error - TS2322 - Type '({ onClick, isSelected }: { onClick: any; isSelected: any; }, { ref, ...triggerProps }: { [x: string]: any; ref: any; }) => Element' is not assignable to type 'RenderCustomTriggerButton'.
				renderCustomTriggerButton={renderCustomTriggerButton}
				objectAri={entityAri}
				onDialogClose={handleDialogClose}
				copyTooltipText={formatMessage(messages.shareTooltipText)}
				shareIntegrations={shareIntegrations}
				integrationMode="tabs"
				shareFormHelperMessage={formatMessage(messages.shareJwmHelperMessage)}
			/>
		</AsyncShareProjectNudge>
	);
};
