import type { GenericIssueField } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import type { Locale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import { ASSIGNEE_TYPE, DUE_DATE_TYPE, SUMMARY_TYPE } from '@atlassian/jira-platform-field-config';
import { formatTimezonedWithLocale } from '@atlassian/jira-platform-utils-date-fns/src/main.tsx';
import type { Field } from '@atlassian/jira-router-resources-business-configuration/src/types';
import type { CreateIssueInput, ExtraCreateIssuePayload } from '../../types';
import { RANK_AFTER_ID, RANK_BEFORE_ID } from './constants';
import type { MutationCreateIssueInput } from './types';

export const buildInput = (
	input: CreateIssueInput,
	locale: Locale,
	extraPayload: ExtraCreateIssuePayload,
	categoryField: GenericIssueField | null,
	startDateField: Field | null,
	sprintField: GenericIssueField | null,
	storyPointEstimateField: GenericIssueField | null,
	storyPointsField: GenericIssueField | null,
) => {
	const result: MutationCreateIssueInput = {
		projectId: input.projectId,
		issueType: input.issueType.id,
		fields: {
			singleLineTextFields: [
				{
					fieldId: SUMMARY_TYPE,
					text: input.summary,
				},
			],
			singleSelectUserPickerFields: [],
			datePickerFields: [],
			numberFields: [],
		},
		rank: {},
	};

	if (input.category && categoryField?.id) {
		result.fields.singleLineTextFields.push({
			fieldId: categoryField.id,
			text: input.category.optionId,
		});
	}

	if (input.assignee) {
		result.fields.singleSelectUserPickerFields.push({
			fieldId: ASSIGNEE_TYPE,
			user: {
				accountId: input.assignee.accountId,
			},
		});
	}

	if (input.startDate && startDateField?.id) {
		result.fields.datePickerFields.push({
			fieldId: startDateField?.id,
			date: {
				formattedDate: formatTimezonedWithLocale(input.startDate, 'dd/MMM/yy', locale, 'UTC'),
			},
		});
	}

	if (input.dueDate) {
		result.fields.datePickerFields.push({
			fieldId: DUE_DATE_TYPE,
			date: {
				formattedDate: formatTimezonedWithLocale(input.dueDate, 'dd/MMM/yy', locale, 'UTC'),
			},
		});
	}

	if (input.priority) {
		result.fields.priority = {
			priorityId: input.priority.priorityId,
		};
	}

	if (input.parentIssueId) {
		result.fields.parentField = {
			issueId: input.parentIssueId,
		};
	}

	if (input.rankBefore) {
		result.rank.before = {
			id: RANK_BEFORE_ID,
			issueId: input.rankBefore.issueId,
		};
	}

	if (input.rankAfter) {
		result.rank.after = {
			id: RANK_AFTER_ID,
			issueId: input.rankAfter.issueId,
		};
	}

	if (extraPayload.transitionId) {
		result.transitionId = extraPayload.transitionId;
	}

	if (input.storyPointEstimate != null && storyPointEstimateField?.id) {
		result.fields.numberFields.push({
			fieldId: storyPointEstimateField.id,
			value: input.storyPointEstimate,
		});
	}

	if (input.storyPoints != null && storyPointsField?.id) {
		result.fields.numberFields.push({
			fieldId: storyPointsField.id,
			value: input.storyPoints,
		});
	}

	if (input.sprint && sprintField?.id) {
		result.fields.sprintsField = {
			fieldId: sprintField.id,
			sprints: [{ sprintId: input.sprint.id }],
		};
	}

	return result;
};
