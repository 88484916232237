import { useCallback } from 'react';
import { useCollabStore } from '@atlassian/jira-business-collaboration/src/controllers/collab-store/index.tsx';
import { getPresenceNodeId } from '@atlassian/jira-business-collaboration/src/utils/get-presence-node-id/index.tsx';
import { SELECTED_ISSUE_PARAM } from '@atlassian/jira-business-constants';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { useQueryParam } from '@atlassian/react-resource-router';
import usePressTracing from '@atlassian/react-ufo/use-press-tracing';
import { ISSUE_KEY_ID } from '../../common/constants';
import type { BoardIssue } from '../../common/types';

export const useViewIssue = () => {
	const ufoPressTracing = usePressTracing('jwm.board.issue-view-opened');
	const [, setSelectedIssueKey] = useQueryParam(SELECTED_ISSUE_PARAM);
	const [, { sendNodeSelected }] = useCollabStore();
	const project = useProject();
	const projectId = String(project.id);

	return useCallback(
		(issue: BoardIssue, withCmd: boolean) => {
			if (issue.id && issue.fields[ISSUE_KEY_ID].value) {
				const issueKey = issue.fields[ISSUE_KEY_ID].value;

				if (withCmd) {
					typeof window !== 'undefined' && window.open(`/browse/${issueKey}`, '_blank');
				} else {
					ufoPressTracing();
					setSelectedIssueKey(issueKey);

					const nodeId = getPresenceNodeId({
						projectId,
						viewId: 'board',
						nodeId: String(issue.id),
					});
					sendNodeSelected({ nodeId });
				}
			}
		},
		[projectId, sendNodeSelected, setSelectedIssueKey, ufoPressTracing],
	);
};
