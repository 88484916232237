import type React from 'react';
import { useCallback } from 'react';
import noop from 'lodash/noop';
import { SELECTED_ISSUE_PARAM, SELECTED_COMMENT_PARAM } from '@atlassian/jira-business-constants';
import { useQueryParams } from '@atlassian/jira-software-router-utils/src/services/query-params/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { createStore, createHook } from '@atlassian/react-sweet-state';
import { localStorage } from './utils';

export const STORAGE_KEY = 'jwm.sidebar';
const SIDEBAR_MIN_WIDTH_OLD = 360;
const SIDEBAR_MIN_WIDTH = 435;
export const getSidebarQueryParams = () => [SELECTED_ISSUE_PARAM, SELECTED_COMMENT_PARAM];

export const getSidebarMinWidth = () =>
	isVisualRefreshEnabled() ? SIDEBAR_MIN_WIDTH : SIDEBAR_MIN_WIDTH_OLD;

type State = {
	content?: React.ReactElement | null;
	sidebarWidth: number;
	onSidebarClose: () => void;
};

const setSidebarContent =
	(content: React.ReactElement | null) =>
	({ setState, getState }: { setState: (arg1: State) => void; getState: () => State }) => {
		const state = getState();
		setState({
			...state,
			content,
		});
	};

const setSidebarWidth =
	(width: number) =>
	({ setState, getState }: { setState: (arg1: State) => void; getState: () => State }) => {
		const state = getState();
		const newWidth = Math.max(width, getSidebarMinWidth());
		if (newWidth === state.sidebarWidth) {
			return;
		}

		setState({
			...state,
			sidebarWidth: newWidth,
		});

		localStorage.set(STORAGE_KEY, newWidth);
	};

const setSidebarOnClose =
	(onSidebarClose: () => void) =>
	({ setState, getState }: { setState: (arg1: State) => void; getState: () => State }) => {
		const state = getState();

		setState({
			...state,
			onSidebarClose,
		});
	};

const closeSidebar =
	() =>
	({ setState, getState }: { setState: (arg1: State) => void; getState: () => State }) => {
		const state = getState();

		state.onSidebarClose();

		setState({
			...state,
			content: null,
			onSidebarClose: noop,
		});
	};

const actions = {
	setSidebarContent,
	setSidebarWidth,
	setSidebarOnClose,
	closeSidebar,
} as const;

const Store = createStore<State, typeof actions>({
	initialState: {
		content: null,
		sidebarWidth: localStorage.get(STORAGE_KEY) || getSidebarMinWidth(),
		onSidebarClose: noop,
	},
	actions,
	name: 'JWMSidebarStore',
});

const useHook = createHook(Store);

type SidebarActions = {
	setSidebarContent: (content: React.ReactElement | null) => void;
	setSidebarWidth: (width: number) => void;
	setSidebarOnClose: (onSidebarClose: () => void) => void;
	closeSidebar: () => void;
	resetSidebarQueryParams: () => void;
};

export const useSidebar = (): [State, SidebarActions] => {
	const [state, hookActions] = useHook();

	const sidebarQueryParams = getSidebarQueryParams();

	const [, setQueryParams] = useQueryParams(...sidebarQueryParams);

	const resetSidebarQueryParams = useCallback(() => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const newQueryParams = sidebarQueryParams.reduce<Record<string, any>>(
			(acc, param) => Object.assign(acc, { [param]: undefined }),
			{},
		);

		setQueryParams(newQueryParams);
	}, [setQueryParams, sidebarQueryParams]);

	return [
		state,
		{
			...hookActions,
			resetSidebarQueryParams,
		},
	];
};
