import { useEffect } from 'react';
import type { SubscribePayload } from 'graphql-ws';
import { isPageVisible, supportedVisiblityEvent } from '@atlassian/jira-common-page-visibility';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { getUserLocation } from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';
import { WS_CLOSE_ON_IDLE } from '../../constants';
import { isSSR } from '../../utils';
import { getClient } from '../../utils/client';
import { type SinkError, getSinkError } from '../../utils/sink';

// Replace with lodash/noop
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

type ErrorAttributes = {
	operationName?: string | null;
	requestId?: string;
};

type Unsubscribe = () => void;

interface SubscribeSink<R> {
	next: (value: R) => void;
	error?: (error: Error, attributes: ErrorAttributes) => void;
	complete?: () => void;
}

const subscribe = <Response,>(
	payload: SubscribePayload,
	sink: SubscribeSink<Response>,
): Unsubscribe | undefined => {
	const client = getClient();
	if (!client) {
		return;
	}

	const { query, variables, operationName, extensions } = payload;
	const { next, error, complete = noop } = sink;
	let requestId: string;

	return client.subscribe(
		{
			query,
			variables,
			operationName,
			extensions: {
				...(extensions ?? {}),
				...(typeof process.env.AGG_BRANCH_SLUG === 'string' && process.env.AGG_BRANCH_SLUG !== ''
					? { schema: process.env.AGG_BRANCH_SLUG }
					: {}),
			},
		},
		{
			next: (value) => {
				// @ts-expect-error agg prop is missing on generic "extensions" type
				const aggRequestId = value?.extensions?.agg?.request_id;
				if (aggRequestId) {
					requestId = aggRequestId;
				}
				// @ts-expect-error incompatible types between two libraries
				next(value);
			},
			complete,
			error: (sinkError: SinkError) => {
				const errorInfo = getSinkError(sinkError);
				const attributes = { operationName, requestId, userLocation: getUserLocation() };

				error?.(errorInfo, attributes);

				fireErrorAnalytics({
					meta: {
						id: 'subscription',
						packageName: 'jiraGraphqlSubscription',
						teamName: 'uip-graphql',
					},
					error: errorInfo,
					attributes,
					sendToPrivacyUnsafeSplunk: true,
				});
			},
		},
	);
};

export const useSubscribe = <Response = Record<string, unknown>,>(
	payload: SubscribePayload,
	sink: SubscribeSink<Response>,
) => {
	useEffect(() => {
		let unsubscribe: Unsubscribe | undefined = subscribe(payload, sink);
		let timeout: NodeJS.Timeout | undefined;

		const onVisibilityChange = () => {
			if (isPageVisible()) {
				if (timeout) {
					clearTimeout(timeout);
					timeout = undefined;
				} else {
					unsubscribe = subscribe(payload, sink);
				}
			} else if (!timeout) {
				timeout = setTimeout(() => {
					unsubscribe?.();
					unsubscribe = undefined;
					timeout = undefined;
				}, WS_CLOSE_ON_IDLE);
			}
		};

		if (!isSSR()) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener(supportedVisiblityEvent, onVisibilityChange);
		}

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener(supportedVisiblityEvent, onVisibilityChange);
			unsubscribe?.();
		};
	}, [payload, sink]);
};
