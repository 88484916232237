import uuid from 'uuid';
import { TRIGGER_POINT_KEY_NOT_DEFINED } from '@atlassian/jira-issue-transition-common-types/src/common/constants/index.tsx';
import type { State } from './types';

export const initialState: State = {
	inProgressTransitionID: uuid.v4(),
	isModalOpen: false,
	isBenefitsModalOpen: false,
	isSubsequentLoad: false,
	payload: {
		issueId: '',
		issueKey: '',
		transitionId: '',
	},
	id: 'issue-transition-dialog',
	title: null,
	fetchedIssueId: '',
	triggerPointKey: TRIGGER_POINT_KEY_NOT_DEFINED,
	openLegacyTransitionsDialog: null,
	onDialogSuccess: null,
	onDialogCancel: null,
	onDialogError: null,
};

export const OPEN_MODERN_TRANSITION_MODAL = 'OPEN_MODERN_TRANSITION_MODAL';
export const OPEN_LEGACY_TRANSITION_MODAL = 'OPEN_LEGACY_TRANSITION_MODAL';
