import { useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import type { BusinessIssueField } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { CATEGORY_TYPE, GOALS_CF_TYPE, SPRINT_TYPE } from '@atlassian/jira-platform-field-config';
import {
	transformCategories,
	transformUsers,
	transformStatuses,
	transformPriorities,
	transformDefaultField,
	transformIssueTypes,
	transformSprints,
	transformValuesData,
} from '../../common/utils/transform-field-values';
import type { TransformedInitialData } from '../../common/utils/transform-field-values/types';
import type { InitialData } from './types';

const transformInitialCategories = (data: InitialData) => {
	if (
		data.jira.jwmCategoryOptions?.edges != null &&
		Array.isArray(data.jira.jwmCategoryOptions.edges)
	) {
		return {
			totalCount: data.jira.jwmCategoryOptions.totalCount,
			options: transformCategories(data.jira.jwmCategoryOptions),
		};
	}
	return undefined;
};

const transformInitialDataOld = (data: InitialData): TransformedInitialData => {
	const { components, issuetype, priority, labels, status, users, recentlyUsedUsers, sprints } =
		data.jira.jqlBuilder;

	const transformedData: TransformedInitialData = {
		labels: { totalCount: 0, options: [] },
		components: { totalCount: 0, options: [] },
		priority: { totalCount: 0, options: [] },
		users: { totalCount: 0, options: [] },
		issuetype: { totalCount: 0, options: [] },
		status: { totalCount: 0, options: [] },
		[CATEGORY_TYPE]: { totalCount: 0, options: [] },
		[SPRINT_TYPE]: { totalCount: 0, options: [] },
	};

	if (components?.edges != null && Array.isArray(components.edges)) {
		transformedData.components = {
			totalCount: components.totalCount,
			options: transformDefaultField(components),
		};
	}

	if (issuetype?.edges != null && Array.isArray(issuetype.edges)) {
		transformedData.issuetype = {
			totalCount: issuetype.totalCount,
			options: transformIssueTypes(issuetype),
		};
	}

	if (priority?.edges != null && Array.isArray(priority.edges)) {
		transformedData.priority = {
			totalCount: priority.totalCount,
			options: transformPriorities(priority),
		};
	}

	if (labels?.edges != null && Array.isArray(labels.edges)) {
		transformedData.labels = {
			totalCount: labels.totalCount,
			options: transformDefaultField(labels),
		};
	}

	if (status?.edges != null && Array.isArray(status.edges)) {
		transformedData.status = {
			totalCount: status.totalCount,
			options: transformStatuses(status),
		};
	}

	if (users?.edges != null && Array.isArray(users.edges)) {
		const usersData = transformUsers(users);

		const recentlyUsedUsersData =
			recentlyUsedUsers?.edges != null && Array.isArray(recentlyUsedUsers.edges)
				? transformUsers(recentlyUsedUsers)
				: null;

		transformedData.users = {
			totalCount: users.totalCount,
			options:
				// combine `recentlyUsedUsers` and `users` options to improve relevancy of the users field
				recentlyUsedUsersData != null
					? uniqBy([...recentlyUsedUsersData, ...usersData], ({ value }) => value)
					: usersData,
		};
	}

	const categories = transformInitialCategories(data);
	if (categories) {
		transformedData[CATEGORY_TYPE] = categories;
	}

	if (sprints?.edges != null && Array.isArray(sprints.edges)) {
		transformedData[SPRINT_TYPE] = {
			options: transformSprints(sprints),
			totalCount: sprints.totalCount,
		};
	}

	return transformedData;
};

export const transformInitialData = (data: InitialData): TransformedInitialData => {
	if (fg('jsw_list_view_-_all_the_fields')) {
		const transformedData = transformValuesData(data.jira.jqlBuilder);

		const categories = transformInitialCategories(data);
		if (categories) {
			transformedData[CATEGORY_TYPE] = categories;
		}

		return transformedData;
	}
	return transformInitialDataOld(data);
};

export const fireFieldValuesErrorAnalytics = (
	errorId: string,
	errorMessage: string,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any,
) => {
	fireErrorAnalytics({
		meta: {
			id: errorId,
			packageName: 'jiraBusinessFilters',
			teamName: 'wanjel',
		},
		attributes: {
			message: errorMessage,
		},
		error,
		sendToPrivacyUnsafeSplunk: true,
	});
};

export const useGoalsJqlTerm = (filterFields?: BusinessIssueField[]) => {
	const goalsJqlTerm = useMemo(
		() => filterFields?.find((field: BusinessIssueField) => field?.type === GOALS_CF_TYPE)?.id,
		[filterFields],
	);

	return goalsJqlTerm;
};
