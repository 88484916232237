import { useMemo } from 'react';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { useProjectContext } from '@atlassian/jira-providers-project-context';
import { NTH_PROJECT_CREATE } from '../../common/constants';
import { inviteUsersSessionStorage } from '../../common/util';

export const useShouldShowInviteUserModalBasedOnSessionStorage = (shouldSkip?: boolean) => {
	const { data: projectContext } = useProjectContext();
	const currentProjectKey = projectContext?.projectKey;
	const [expConfig] = UNSAFE_noExposureExp('invite-users-on-project-create-experiment');

	const shouldShowInviteUserModal = useMemo(
		() =>
			!!(
				inviteUsersSessionStorage.get(NTH_PROJECT_CREATE) &&
				currentProjectKey &&
				inviteUsersSessionStorage.get(NTH_PROJECT_CREATE) === currentProjectKey
			),
		[currentProjectKey],
	);

	return (
		!shouldSkip && shouldShowInviteUserModal && expConfig.get('shouldShowModal', false) === true
	);
};
