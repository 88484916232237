import { useCallback, useMemo } from 'react';
import type { UserPreferences } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import { useUserPreferencesStoreValue, useUserPreferencesStoreActions } from './context';
import type { Preferences, Value } from './types';
import { saveUserPreferenceValue, removeUserPreferenceValue } from './utils';

export const useUserPreferencesValue = (userPreferenceKey: UserPreferences) => {
	const baseUrl = '';
	const [value, { setUserPreferenceValue, deleteUserPreferenceValue }] =
		useUserPreferencesStoreValue(userPreferenceKey);

	const setValue = useCallback(
		(newValue: Value) => {
			setUserPreferenceValue(userPreferenceKey, newValue);
			return saveUserPreferenceValue(baseUrl, userPreferenceKey, newValue);
		},
		[baseUrl, setUserPreferenceValue, userPreferenceKey],
	);

	const deleteValue = useCallback(() => {
		deleteUserPreferenceValue(userPreferenceKey);
		return removeUserPreferenceValue(baseUrl, userPreferenceKey);
	}, [baseUrl, deleteUserPreferenceValue, userPreferenceKey]);

	const setUserPreferences = useMemo(() => ({ setValue, deleteValue }), [setValue, deleteValue]);

	return [value, setUserPreferences] as const;
};

export const useUserPreferencesActions = () => {
	const baseUrl = '';

	const [, actions] = useUserPreferencesStoreActions();

	const userPreferenceActions = useMemo(() => {
		const setUserPreferenceValue = (userPreferenceKey: UserPreferences, value: Value) => {
			actions.setUserPreferenceValue(userPreferenceKey, value);
			saveUserPreferenceValue(baseUrl, userPreferenceKey, value);
		};

		const deleteUserPreferenceValue = (userPreferenceKey: UserPreferences) => {
			actions.deleteUserPreferenceValue(userPreferenceKey);
			removeUserPreferenceValue(baseUrl, userPreferenceKey);
		};

		const setUserPreferences = (userPreferences?: Preferences) => {
			userPreferences &&
				Object.keys(userPreferences).length !== 0 &&
				actions.setUserPreferences(userPreferences);
		};

		return {
			setUserPreferenceValue,
			setUserPreferences,
			deleteUserPreferenceValue,
			getUserPreferenceValue: actions.getUserPreferenceValue,
		};
	}, [actions, baseUrl]);

	return [null, userPreferenceActions] as const;
};
