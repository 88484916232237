{
  "__schema": {
    "types": [
      {
        "kind": "UNION",
        "name": "AllActivityFeedItemUnion",
        "possibleTypes": [
          {
            "name": "HistoryItem"
          },
          {
            "name": "CommentItem"
          },
          {
            "name": "WorklogItem"
          },
          {
            "name": "IncidentItem"
          },
          {
            "name": "ApprovalItem"
          },
          {
            "name": "HiddenCommentItem"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ApprovalActivityValueUnion",
        "possibleTypes": [
          {
            "name": "ApprovalCreated"
          },
          {
            "name": "ApproverDecision"
          },
          {
            "name": "ApprovalCompleted"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ConfluencePageUnion",
        "possibleTypes": [
          {
            "name": "ConfluencePage"
          },
          {
            "name": "FailedRemoteLink"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GQLCategorizedField",
        "possibleTypes": [
          {
            "name": "GQLSortableField"
          },
          {
            "name": "GQLSearchableField"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GadgetParameters",
        "possibleTypes": [
          {
            "name": "VotedGadgetParameters"
          },
          {
            "name": "AssignedToMeGadgetParameters"
          },
          {
            "name": "WatchedGadgetParameters"
          },
          {
            "name": "InProgressGadgetParameters"
          },
          {
            "name": "FilterResultsGadgetParameters"
          },
          {
            "name": "TwoDimensionalStatisticsParameters"
          },
          {
            "name": "PieChartParameters"
          },
          {
            "name": "IssueStatisticsParameters"
          },
          {
            "name": "WorkloadPieChartParameters"
          },
          {
            "name": "ActivityStreamGadgetParameters"
          },
          {
            "name": "CreatedVsResolvedGadgetParameters"
          },
          {
            "name": "SprintGadgetParameters"
          },
          {
            "name": "ProjectsGadgetParameters"
          },
          {
            "name": "ConnectGadgetParameters"
          },
          {
            "name": "NotConfiguredGadgetParameters"
          },
          {
            "name": "UnsupportedGadgetParameters"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GroupByFieldUnion",
        "possibleTypes": [
          {
            "name": "PriorityField"
          },
          {
            "name": "StatusField"
          },
          {
            "name": "UserField"
          },
          {
            "name": "LabelsField"
          },
          {
            "name": "SelectCustomField"
          },
          {
            "name": "JWMCategoryCustomField"
          },
          {
            "name": "IssueTypeField"
          },
          {
            "name": "StoryPointsField"
          },
          {
            "name": "SprintCustomField"
          },
          {
            "name": "FloatCustomField"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GroupSectionItem",
        "possibleTypes": [
          {
            "name": "FieldSectionItem"
          },
          {
            "name": "EcosystemSectionItem"
          },
          {
            "name": "ForgeSectionItem"
          },
          {
            "name": "PanelSectionItem"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "HistoryValue",
        "possibleTypes": [
          {
            "name": "AssigneeFieldValue"
          },
          {
            "name": "PriorityFieldValue"
          },
          {
            "name": "StatusFieldValue"
          },
          {
            "name": "WorkLogFieldValue"
          },
          {
            "name": "RespondersFieldValue"
          },
          {
            "name": "GenericFieldValue"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "IncidentActivityValueUnion",
        "possibleTypes": [
          {
            "name": "ResponderAlertAssignedValue"
          },
          {
            "name": "AlertValue"
          },
          {
            "name": "ResponderAlertPriorityChangedValue"
          },
          {
            "name": "IccSessionValue"
          },
          {
            "name": "IccSessionInvitationValue"
          },
          {
            "name": "StakeholderUpdatedValue"
          },
          {
            "name": "IncidentStakeholderActionValue"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "IssueFieldUnion",
        "possibleTypes": [
          {
            "name": "PriorityField"
          },
          {
            "name": "StatusField"
          },
          {
            "name": "IssueTypeField"
          },
          {
            "name": "ProjectComponentsField"
          },
          {
            "name": "LabelsField"
          },
          {
            "name": "WatchersField"
          },
          {
            "name": "UserField"
          },
          {
            "name": "TextField"
          },
          {
            "name": "VersionsField"
          },
          {
            "name": "DateField"
          },
          {
            "name": "DateTimeField"
          },
          {
            "name": "IssueKeyField"
          },
          {
            "name": "ProjectField"
          },
          {
            "name": "ResolutionField"
          },
          {
            "name": "TimeTrackingField"
          },
          {
            "name": "TimeOriginalEstimateField"
          },
          {
            "name": "TimeEstimateField"
          },
          {
            "name": "TimeSpentField"
          },
          {
            "name": "StoryPointsField"
          },
          {
            "name": "EpicLinkField"
          },
          {
            "name": "EpicNameField"
          },
          {
            "name": "ColorField"
          },
          {
            "name": "EpicColorField"
          },
          {
            "name": "SprintCustomField"
          },
          {
            "name": "SelectCustomField"
          },
          {
            "name": "FlaggedCustomField"
          },
          {
            "name": "UserPickerCustomField"
          },
          {
            "name": "TextAreaCustomField"
          },
          {
            "name": "FloatCustomField"
          },
          {
            "name": "DatePickerCustomField"
          },
          {
            "name": "TextFieldCustomField"
          },
          {
            "name": "MultiSelectCustomField"
          },
          {
            "name": "LabelsCustomField"
          },
          {
            "name": "MultiUserPickerCustomField"
          },
          {
            "name": "PeopleCustomField"
          },
          {
            "name": "UrlCustomField"
          },
          {
            "name": "VersionCustomField"
          },
          {
            "name": "GroupPickerCustomField"
          },
          {
            "name": "MultiVersionCustomField"
          },
          {
            "name": "MultiGroupPickerCustomField"
          },
          {
            "name": "CheckBoxesCustomField"
          },
          {
            "name": "RadioButtonsCustomField"
          },
          {
            "name": "DateTimeCustomField"
          },
          {
            "name": "SeverityField"
          },
          {
            "name": "AffectedServicesField"
          },
          {
            "name": "TechLeadField"
          },
          {
            "name": "AttachmentsField"
          },
          {
            "name": "GlancesField"
          },
          {
            "name": "SecurityLevelField"
          },
          {
            "name": "IssueLinksField"
          },
          {
            "name": "IssuesInEpicField"
          },
          {
            "name": "SubtasksField"
          },
          {
            "name": "ChildIssuesField"
          },
          {
            "name": "CascadingSelectCustomField"
          },
          {
            "name": "CmdbField"
          },
          {
            "name": "SLACustomField"
          },
          {
            "name": "ProjectCustomField"
          },
          {
            "name": "EpicStatusField"
          },
          {
            "name": "ParentField"
          },
          {
            "name": "JWMCategoryCustomField"
          },
          {
            "name": "CommentSummaryField"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "IssueSections",
        "possibleTypes": [
          {
            "name": "PrimarySecondaryContextSections"
          },
          {
            "name": "JsdSections"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraActiveBackgroundDetailsResult",
        "possibleTypes": [
          {
            "name": "JiraColorBackground"
          },
          {
            "name": "JiraGradientBackground"
          },
          {
            "name": "JiraMediaBackground"
          },
          {
            "name": "JiraAttachmentBackground"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraAtlassianIntelligenceAction",
        "possibleTypes": [
          {
            "name": "JiraAccessAtlassianIntelligenceFeature"
          },
          {
            "name": "JiraEnableAtlassianIntelligenceDeepLink"
          },
          {
            "name": "JiraContactOrgAdminToEnableAtlassianIntelligence"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraBackgroundUploadTokenResult",
        "possibleTypes": [
          {
            "name": "JiraBackgroundUploadToken"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraChildIssues",
        "possibleTypes": [
          {
            "name": "JiraChildIssuesWithinLimit"
          },
          {
            "name": "JiraChildIssuesExceedingLimit"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraCommentVisibility",
        "possibleTypes": [
          {
            "name": "JiraRoleVisibility"
          },
          {
            "name": "JiraGroupVisibility"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraContainerNavigationResult",
        "possibleTypes": [
          {
            "name": "JiraContainerNavigation"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraFavourite",
        "possibleTypes": [
          {
            "name": "JiraProject"
          },
          {
            "name": "JiraSystemFilter"
          },
          {
            "name": "JiraCustomFilter"
          },
          {
            "name": "JiraDashboard"
          },
          {
            "name": "JiraPlan"
          },
          {
            "name": "JiraBoard"
          },
          {
            "name": "JiraServiceManagementQueue"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraFormattingExpression",
        "possibleTypes": [
          {
            "name": "JiraFormattingSingleValueOperand"
          },
          {
            "name": "JiraFormattingTwoValueOperand"
          },
          {
            "name": "JiraFormattingMultipleValueOperand"
          },
          {
            "name": "JiraFormattingNoValueOperand"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraGlobalPermissionGrantsResult",
        "possibleTypes": [
          {
            "name": "JiraGlobalPermissionGrantsList"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraGrantTypeValue",
        "possibleTypes": [
          {
            "name": "JiraDefaultGrantTypeValue"
          },
          {
            "name": "JiraUserGrantTypeValue"
          },
          {
            "name": "JiraProjectRoleGrantTypeValue"
          },
          {
            "name": "JiraGroupGrantTypeValue"
          },
          {
            "name": "JiraIssueFieldGrantTypeValue"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraGroupLayoutItem",
        "possibleTypes": [
          {
            "name": "JiraFieldLayoutItem"
          },
          {
            "name": "JiraEcosystemLayoutItem"
          },
          {
            "name": "JiraForgeLayoutItem"
          },
          {
            "name": "JiraPanelLayoutItem"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueCreateAdditionalServicesResult",
        "possibleTypes": [
          {
            "name": "JiraIssueCreateAdditionalServices"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueCreateFieldsResult",
        "possibleTypes": [
          {
            "name": "JiraIssueCreateFields"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueCreateLayoutResult",
        "possibleTypes": [
          {
            "name": "JiraIssueCreateLayout"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueExportEvent",
        "possibleTypes": [
          {
            "name": "JiraIssueExportTaskSubmitted"
          },
          {
            "name": "JiraIssueExportTaskProgress"
          },
          {
            "name": "JiraIssueExportTaskCompleted"
          },
          {
            "name": "JiraIssueExportTaskTerminated"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueItemContainerItem",
        "possibleTypes": [
          {
            "name": "JiraIssueItemFieldItem"
          },
          {
            "name": "JiraIssueItemPanelItem"
          },
          {
            "name": "JiraIssueItemGroupContainer"
          },
          {
            "name": "JiraIssueItemTabContainer"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueItemContainersResult",
        "possibleTypes": [
          {
            "name": "JiraIssueItemContainers"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueItemGroupContainerItem",
        "possibleTypes": [
          {
            "name": "JiraIssueItemFieldItem"
          },
          {
            "name": "JiraIssueItemPanelItem"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueItemTabContainerItem",
        "possibleTypes": [
          {
            "name": "JiraIssueItemFieldItem"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueLayout",
        "possibleTypes": [
          {
            "name": "JiraDefaultIssueLayout"
          },
          {
            "name": "JiraIssueCreateLayout"
          },
          {
            "name": "JiraLegacyIssueLayout"
          },
          {
            "name": "JsdDefaultIssueLayout"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueLayoutConfiguration",
        "possibleTypes": [
          {
            "name": "JiraIssueLayoutConfigurationResult"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueLayoutFieldConfigurationHiddenIn",
        "possibleTypes": [
          {
            "name": "JiraIssueLayoutFieldConfigurationHiddenInGlobal"
          },
          {
            "name": "JiraIssueLayoutFieldConfigurationHiddenInLayoutOwner"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueLayoutFieldPanelItemConfiguration",
        "possibleTypes": [
          {
            "name": "JiraIssueLayoutFieldItemConfiguration"
          },
          {
            "name": "JiraIssueLayoutPanelItemConfiguration"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueLayoutItemConfiguration",
        "possibleTypes": [
          {
            "name": "JiraIssueLayoutContainerConfiguration"
          },
          {
            "name": "JiraIssueLayoutFieldItemConfiguration"
          },
          {
            "name": "JiraIssueLayoutGroupContainerConfiguration"
          },
          {
            "name": "JiraIssueLayoutTabContainerConfiguration"
          },
          {
            "name": "JiraIssueLayoutPanelItemConfiguration"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueLayoutOwner",
        "possibleTypes": [
          {
            "name": "JiraIssueLayoutIssueTypeOwner"
          },
          {
            "name": "JiraIssueLayoutRequestTypeOwner"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueLayoutOwners",
        "possibleTypes": [
          {
            "name": "JiraIssueLayoutOwnersResult"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueResult",
        "possibleTypes": [
          {
            "name": "JiraIssue"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueSearchByFilterResult",
        "possibleTypes": [
          {
            "name": "JiraIssueSearchByFilter"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueSearchByJqlResult",
        "possibleTypes": [
          {
            "name": "JiraIssueSearchByJql"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueSearchError",
        "possibleTypes": [
          {
            "name": "JiraInvalidJqlError"
          },
          {
            "name": "JiraInvalidSyntaxError"
          },
          {
            "name": "JiraServerError"
          },
          {
            "name": "JiraCustomIssueSearchError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraIssueSearchViewResult",
        "possibleTypes": [
          {
            "name": "JiraIssueSearchView"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraJQLGenerationError",
        "possibleTypes": [
          {
            "name": "JiraInvalidJqlError"
          },
          {
            "name": "JiraInvalidSyntaxError"
          },
          {
            "name": "JiraServerError"
          },
          {
            "name": "JiraUIExposedError"
          },
          {
            "name": "JiraUsageLimitExceededError"
          },
          {
            "name": "JiraUnsupportedLanguageError"
          },
          {
            "name": "JiraGeneratedJqlInvalidError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraJqlFieldConnectionResult",
        "possibleTypes": [
          {
            "name": "JiraJqlFieldConnection"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraJqlHydratedQueryResult",
        "possibleTypes": [
          {
            "name": "JiraJqlHydratedQuery"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraJqlQueryHydratedFieldResult",
        "possibleTypes": [
          {
            "name": "JiraJqlQueryHydratedField"
          },
          {
            "name": "JiraJqlQueryHydratedError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraJqlQueryHydratedValueResult",
        "possibleTypes": [
          {
            "name": "JiraJqlQueryHydratedValue"
          },
          {
            "name": "JiraJqlQueryHydratedError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraMediaUploadTokenResult",
        "possibleTypes": [
          {
            "name": "JiraMediaUploadToken"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraNavigationItemResult",
        "possibleTypes": [
          {
            "name": "JiraWorkManagementSavedView"
          },
          {
            "name": "JiraSoftwareBuiltInNavigationItem"
          },
          {
            "name": "JiraAppNavigationItem"
          },
          {
            "name": "JiraShortcutNavigationItem"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraOnSuggestedChildIssueResult",
        "possibleTypes": [
          {
            "name": "JiraSuggestedIssue"
          },
          {
            "name": "JiraSuggestedChildIssueStatus"
          },
          {
            "name": "JiraSuggestedChildIssueError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraOverviewPlanMigrationStateResult",
        "possibleTypes": [
          {
            "name": "JiraOverviewPlanMigrationState"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraPermissionSchemeViewResult",
        "possibleTypes": [
          {
            "name": "JiraPermissionSchemeView"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraProjectConfiguration",
        "possibleTypes": [
          {
            "name": "JiraProjectConfigurationResult"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraProjectNavigationMetadata",
        "possibleTypes": [
          {
            "name": "JiraSoftwareProjectNavigationMetadata"
          },
          {
            "name": "JiraServiceManagementProjectNavigationMetadata"
          },
          {
            "name": "JiraWorkManagementProjectNavigationMetadata"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraProjectsUsingField",
        "possibleTypes": [
          {
            "name": "JiraProjectsUsingFieldResult"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraRemoteIssueLink",
        "possibleTypes": [
          {
            "name": "JiraIssueRemoteIssueLink"
          },
          {
            "name": "JiraConfluenceRemoteIssueLink"
          },
          {
            "name": "JiraWebRemoteIssueLink"
          },
          {
            "name": "JiraCustomRemoteIssueLink"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraSearchableEntity",
        "possibleTypes": [
          {
            "name": "JiraProject"
          },
          {
            "name": "JiraSystemFilter"
          },
          {
            "name": "JiraCustomFilter"
          },
          {
            "name": "JiraBoard"
          },
          {
            "name": "JiraDashboard"
          },
          {
            "name": "JiraIssue"
          },
          {
            "name": "JiraServiceManagementQueue"
          },
          {
            "name": "JiraPlan"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraSectionLayoutItem",
        "possibleTypes": [
          {
            "name": "JiraGroupLayout"
          },
          {
            "name": "JiraTabLayout"
          },
          {
            "name": "JiraFieldLayoutItem"
          },
          {
            "name": "JiraEcosystemLayoutItem"
          },
          {
            "name": "JiraForgeLayoutItem"
          },
          {
            "name": "JiraPanelLayoutItem"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraServiceManagementApproverPrincipal",
        "possibleTypes": [
          {
            "name": "JiraServiceManagementUserApproverPrincipal"
          },
          {
            "name": "JiraServiceManagementGroupApproverPrincipal"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraServiceManagementEntitledEntity",
        "possibleTypes": [
          {
            "name": "JiraServiceManagementEntitlementCustomer"
          },
          {
            "name": "JiraServiceManagementEntitlementOrganization"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraServiceManagementRequestTypePreviewField",
        "possibleTypes": [
          {
            "name": "JiraServiceManagementTextPreviewField"
          },
          {
            "name": "JiraServiceManagementTextAreaPreviewField"
          },
          {
            "name": "JiraServiceManagementDueDatePreviewField"
          },
          {
            "name": "JiraServiceManagementDatePreviewField"
          },
          {
            "name": "JiraServiceManagementDateTimePreviewField"
          },
          {
            "name": "JiraServiceManagementAttachmentPreviewField"
          },
          {
            "name": "JiraServiceManagementSelectPreviewField"
          },
          {
            "name": "JiraServiceManagementMultiSelectPreviewField"
          },
          {
            "name": "JiraServiceManagementMultiUserPickerPreviewField"
          },
          {
            "name": "JiraServiceManagementMultiServicePickerPreviewField"
          },
          {
            "name": "JiraServiceManagementMultiCheckboxesPreviewField"
          },
          {
            "name": "JiraServiceManagementPeoplePreviewField"
          },
          {
            "name": "JiraServiceManagementUnknownPreviewField"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraServiceManagementResponder",
        "possibleTypes": [
          {
            "name": "JiraServiceManagementUserResponder"
          },
          {
            "name": "JiraServiceManagementTeamResponder"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraShareableEntityEditGrant",
        "possibleTypes": [
          {
            "name": "JiraShareableEntityGroupGrant"
          },
          {
            "name": "JiraShareableEntityProjectRoleGrant"
          },
          {
            "name": "JiraShareableEntityUserGrant"
          },
          {
            "name": "JiraShareableEntityProjectGrant"
          },
          {
            "name": "JiraShareableEntityUnknownProjectGrant"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraShareableEntityShareGrant",
        "possibleTypes": [
          {
            "name": "JiraShareableEntityGroupGrant"
          },
          {
            "name": "JiraShareableEntityProjectRoleGrant"
          },
          {
            "name": "JiraShareableEntityProjectGrant"
          },
          {
            "name": "JiraShareableEntityAnonymousAccessGrant"
          },
          {
            "name": "JiraShareableEntityAnyLoggedInUserGrant"
          },
          {
            "name": "JiraShareableEntityUnknownProjectGrant"
          },
          {
            "name": "JiraShareableEntityUserGrant"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraUnsplashImageSearchPageResult",
        "possibleTypes": [
          {
            "name": "JiraUnsplashImageSearchPage"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraVersionConnectionResult",
        "possibleTypes": [
          {
            "name": "JiraVersionConnection"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraVersionResult",
        "possibleTypes": [
          {
            "name": "JiraVersion"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraWorkManagementActiveBackgroundDetailsResult",
        "possibleTypes": [
          {
            "name": "JiraWorkManagementColorBackground"
          },
          {
            "name": "JiraWorkManagementGradientBackground"
          },
          {
            "name": "JiraWorkManagementMediaBackground"
          },
          {
            "name": "JiraWorkManagementAttachmentBackground"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraWorkManagementBackgroundUploadTokenResult",
        "possibleTypes": [
          {
            "name": "JiraWorkManagementBackgroundUploadToken"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraWorkManagementCategoryOptionConnectionResult",
        "possibleTypes": [
          {
            "name": "JiraWorkManagementCategoryOptionConnection"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraWorkManagementFieldConfigurationConnectionResult",
        "possibleTypes": [
          {
            "name": "JiraWorkManagementFieldConfigurationConnection"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraWorkManagementFilterConnectionResult",
        "possibleTypes": [
          {
            "name": "JiraWorkManagementFilterConnection"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraWorkManagementGiraOverviewResult",
        "possibleTypes": [
          {
            "name": "JiraWorkManagementGiraOverview"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraWorkManagementSavedViewResult",
        "possibleTypes": [
          {
            "name": "JiraWorkManagementSavedView"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JiraWorkManagementViewItemConnectionResult",
        "possibleTypes": [
          {
            "name": "JiraWorkManagementViewItemConnection"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JqlFieldConnectionResult",
        "possibleTypes": [
          {
            "name": "JqlFieldConnection"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JqlHydratedQueryResult",
        "possibleTypes": [
          {
            "name": "JqlHydratedQuery"
          },
          {
            "name": "QueryError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JqlQueryHydratedFieldResult",
        "possibleTypes": [
          {
            "name": "JqlQueryHydratedField"
          },
          {
            "name": "JqlQueryHydratedError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "JqlQueryHydratedValueResult",
        "possibleTypes": [
          {
            "name": "JqlQueryHydratedValue"
          },
          {
            "name": "JqlQueryHydratedError"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "LayoutDefiner",
        "possibleTypes": [
          {
            "name": "FieldLayoutScreen"
          },
          {
            "name": "JiraRequestType"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "MediaUploadTokenUnion",
        "possibleTypes": [
          {
            "name": "MediaUploadToken"
          },
          {
            "name": "FailedMediaUploadToken"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "MergedFieldUnion",
        "possibleTypes": [
          {
            "name": "IssueTypeField"
          },
          {
            "name": "StatusField"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ProjectCategoryGadgetUnion",
        "possibleTypes": [
          {
            "name": "ProjectsGadgetItem"
          },
          {
            "name": "CategoriesGadgetItem"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "RemoteIssueDetailsUnion",
        "possibleTypes": [
          {
            "name": "AssociatedIssueWrapper"
          },
          {
            "name": "FailedRemoteIssue"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SectionItem",
        "possibleTypes": [
          {
            "name": "GroupLayout"
          },
          {
            "name": "TabLayout"
          },
          {
            "name": "FieldSectionItem"
          },
          {
            "name": "EcosystemSectionItem"
          },
          {
            "name": "ForgeSectionItem"
          },
          {
            "name": "PanelSectionItem"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "TimeSeriesChartAdditionalPropertiesUnion",
        "possibleTypes": [
          {
            "name": "CreatedVsResolvedProperties"
          }
        ]
      }
    ]
  }
}