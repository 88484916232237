import { useCallback } from 'react';
import type { SortingAttributes } from '@atlassian/jira-business-common/src/common/types/sorting.tsx';
import { useIssueTypeFieldConfig } from '@atlassian/jira-business-entity-project/src/services/issue-type-field-config/index.tsx';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import type { Field } from '@atlassian/jira-business-fields/src/utils/index.tsx';
import { SORTING_PREFERENCE } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { useViewPreference } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import { useProjectStatuses } from '../project-statuses/main.tsx';
import { STATUS_SORT_ORDER } from './constants';
import type { UseSorterReturn } from './types';
import { createSortingGettersAndOrders, sortListBy } from './utils';

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
const EMPTY_SORTING_ATTRIBUTE = {} as SortingAttributes;

export const useSorting = () => {
	const [sorting, setSorting] = useViewPreference<SortingAttributes>(SORTING_PREFERENCE);

	return [sorting || EMPTY_SORTING_ATTRIBUTE, setSorting] as const;
};

const emptyArray: Array<never> = [];

export const useSorter = <
	TNode extends { rank?: string; fields?: Array<Field> },
>(): UseSorterReturn<TNode> => {
	const [sorting] = useSorting();

	const [sortingFieldConfig] = useIssueTypeFieldConfig({
		screen: 'VIEW',
		issueTypeId: 'default',
		fieldId: sorting.sortBy,
	});

	const [priorityFieldConfig] = useIssueTypeFieldConfig({
		screen: 'VIEW',
		issueTypeId: 'default',
		fieldId: 'priority',
	});

	const { data } = useIssueTypesAndFields({
		issueOperation: 'VIEW',
	});

	const sortingFieldConfigFieldType =
		sortingFieldConfig?.fieldType ??
		data?.fields?.find((field) => field?.id === sorting.sortBy)?.type;

	const { statuses } = useProjectStatuses();

	const priorities = priorityFieldConfig?.options || emptyArray;

	const sortFunction = useCallback(
		(issues: TNode[]) => {
			const getPrioritiesSortedList = () =>
				[...priorities].reverse().reduce<Record<string, number>>((acc, next, index) => {
					acc[next.priorityId] = index;
					return acc;
				}, {});

			const getIssueTypesSortedList = () =>
				[...data.issueTypes]
					.sort((a, b) => a.name.localeCompare(b.name))
					.reduce<Record<string, number>>((acc, next, index) => {
						acc[next.name] = index;
						return acc;
					}, {});

			const getStatusSortOrder = (key: string): number => {
				if (key in STATUS_SORT_ORDER) {
					return STATUS_SORT_ORDER[key];
				}
				return -1;
			};

			const getStatusesSortedList = () =>
				[...statuses]
					.sort((a, b) => {
						const orderComparison =
							getStatusSortOrder(a.statusCategory.key) - getStatusSortOrder(b.statusCategory.key);
						if (orderComparison !== 0) {
							return orderComparison;
						}
						// If the status category order is the same, then sort alphabetically by name.
						return a.name.localeCompare(b.name);
					})
					.reduce<Record<string, number>>((acc, next, index) => {
						acc[next.name] = index;
						return acc;
					}, {});

			const sortedLists = {
				priorities: getPrioritiesSortedList(),
				issueTypes: getIssueTypesSortedList(),
				statuses: getStatusesSortedList(),
			};

			const { getters, orders } = createSortingGettersAndOrders(
				sorting,
				sortingFieldConfigFieldType,
				sortedLists,
			);

			return sortListBy<TNode>(issues, getters, orders);
		},
		[sorting, sortingFieldConfigFieldType, priorities, data.issueTypes, statuses],
	);

	return { sort: sortFunction };
};
