import React from 'react';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import Modal, { type ModalDialogProps } from '@atlaskit/modal-dialog';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import { shouldBeChoreographed } from '@atlassian/jira-choreographer-services/src/utils.tsx';
import { ChoreographerModal } from './choreographer-modal';

export type JiraModalProps = ModalDialogProps & MaybeChoreographedComponentProps;

export const JiraModal = (props: JiraModalProps) => {
	if (shouldBeChoreographed(props)) {
		const { messageType, ...rest } = props;
		return <ChoreographerModal {...rest} />;
	}

	const { messageType, messageId, onMessageDisposition, ...rest } = props;
	return <Modal {...rest} />;
};

JiraModal.displayName = 'JiraModal';
