import React, { memo, useCallback, useState } from 'react';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { InlineCreateStyles } from '../types';
import InlineCreateForm, { type Props as FormProps } from './inline-create-form';
import InlineCreateTrigger from './inline-create-trigger';

export type Props<TIssue> = FormProps<TIssue> & {
	onToggleForm?: (isFormVisible: boolean) => void;
	inlineCreateStyles?: InlineCreateStyles;
	isOpenByDefault?: boolean;
};

const InlineCreate = <TIssue,>({
	allowedIssueTypeIds,
	appearance,
	fieldIdsToReturn,
	inlineCreateStyles,
	isCompact,
	location,
	maxHierarchyLevel,
	minHierarchyLevel,
	onBlur,
	onPrepareIssueContext,
	onSubmit,
	onToggleForm,
	isOpenByDefault = false,
	popperStrategy,
}: Props<TIssue>) => {
	const [isFormVisible, setIsFormVisible] = useState(isOpenByDefault);
	const {
		data: { issueTypes },
	} = useIssueTypesAndFields({ issueOperation: 'CREATE' });

	const showForm = useCallback(() => {
		setIsFormVisible(true);
		onToggleForm?.(true);
	}, [onToggleForm]);

	const hideForm = useCallback(() => {
		setIsFormVisible(false);
		onToggleForm?.(false);
	}, [onToggleForm]);

	const handleBlur = useCallback(() => {
		hideForm();
		onBlur?.();
	}, [hideForm, onBlur]);

	const isInlineCreateFormVisible = fg('jira_one_screen_onboarding_feature_gate')
		? isFormVisible && issueTypes.length > 0
		: isFormVisible;

	return isInlineCreateFormVisible ? (
		<InlineCreateForm
			allowedIssueTypeIds={allowedIssueTypeIds}
			appearance={appearance}
			fieldIdsToReturn={fieldIdsToReturn}
			isCompact={isCompact}
			location={location}
			maxHierarchyLevel={maxHierarchyLevel}
			minHierarchyLevel={minHierarchyLevel}
			onBlur={handleBlur}
			onPrepareIssueContext={onPrepareIssueContext}
			onSubmit={onSubmit}
			popperStrategy={popperStrategy}
			{...inlineCreateStyles}
		/>
	) : (
		<InlineCreateTrigger
			onClick={showForm}
			isDisabled={fg('resolve-type-error-undefined-avatar-url') && issueTypes.length === 0}
			{...inlineCreateStyles}
		/>
	);
};

const genericMemo: <T>(component: T) => T = memo;

export default genericMemo(InlineCreate);
