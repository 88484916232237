import React, { useRef, memo, type ComponentType } from 'react';
import { JWM_CONTAINER } from '@atlassian/jira-business-app-wrapper/src/constants.tsx';
import type { Attributes } from '@atlassian/jira-product-analytics-bridge';
import ScrollFPSMetric from '@atlassian/jira-scroll-fps-metric';
import UFOLoadHold from '@atlassian/react-ufo/load-hold';
import type { JWM_VIEW } from '../types';
import { FirstInteractionMetric } from './first-interaction';
import { PageLoadMetrics } from './page-load';

type Props = {
	view: JWM_VIEW;
	itemsCount?: number;
	attributes?: Attributes;
	loading?: boolean;
};

export const PerformanceAnalytics: ComponentType<Props> = memo(
	({ view, loading, itemsCount, attributes }: Props) => {
		const loadingRef = useRef<boolean | undefined>(loading);
		loadingRef.current = loading;

		if (loading === true) {
			return <UFOLoadHold name="performance-analytics-loading" />;
		}

		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		const scrollElement = __SERVER__ ? null : document.getElementById(JWM_CONTAINER);
		const product = view.startsWith('software') ? 'jsw' : 'jwm';

		return (
			<>
				<PageLoadMetrics view={view} attributes={attributes} product={product} />
				<FirstInteractionMetric view={view} product={product} />
				<ScrollFPSMetric
					viewId={`${product}.${view}`}
					scrollElement={scrollElement}
					itemsCount={itemsCount}
					attributes={attributes}
				/>
			</>
		);
	},
);
