import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch';
import { useSidebar } from '../../controllers/sidebar';

type Props = {
	children: ReactNode;
	testId?: string;
};

export const ActionsWrapper = ({ children, testId }: Props) => {
	const [{ content }] = useSidebar();
	return (
		<ActionsWrapperStyled
			sidebarOpen={!!content}
			data-testid={testId}
			isVisualRefreshEnabledFG={isVisualRefreshEnabled()}
		>
			{children}
		</ActionsWrapperStyled>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CenterAlignedWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	gap: token('space.100', '8px'),
	// This is needed so the popups from header actions triggers can render options over rest of the content when clicked
	// Timeline header has z-index 12 that's highest amongst views, setting this to 13 to be higher than that
	//  TODO (HRZ-1020): Uncomment below after figuring out a fix
	// zIndex: 13,
});

// When Avatars loaded, it pushes BaseTable a few px down, causing Layout Shift. We're locking min-height to avoid it
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- To migrate as part of go/ui-styling-standard
export const ActionsWrapperStyled = styled(CenterAlignedWrapper)<{
	sidebarOpen: boolean;
	isVisualRefreshEnabledFG?: boolean;
}>({
	minHeight: '40px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	margin: ({ sidebarOpen }) =>
		`0 ${sidebarOpen ? 0 : token('space.negative.300', '-24px')} ${token('space.200', '16px')} ${token('space.negative.300', '-24px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
	padding: ({ isVisualRefreshEnabledFG }) =>
		isVisualRefreshEnabledFG
			? `${token('space.250')} ${token('space.300')}`
			: `${token('space.150', '12px')} ${token('space.300', '24px')}`,
	flexWrap: 'wrap',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backgroundColor: Tokens.ELEVATION_SURFACE_CONTRAST,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	backdropFilter: Tokens.BACKDROP_FILTER,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const LeftActionsWrapper = styled(CenterAlignedWrapper)({
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RightActionsWrapper = CenterAlignedWrapper;
