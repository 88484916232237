import { useMemo } from 'react';
import type { BusinessIssueField } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import { useFieldsResource } from '@atlassian/jira-router-resources-business-fields';

type Return = {
	data: BusinessIssueField[];
	error?: Error;
	loading: boolean;
};

export const useFilterFields = (): Return => {
	const { data: fields, loading } = useFieldsResource();

	const data = useMemo(
		() =>
			fields?.map((field) => ({
				id: field.jqlTerm,
				name: field.displayName,
				type: field.type,
			})) ?? [],
		[fields],
	);

	return {
		data,
		loading,
		error: undefined,
	};
};
