import {
	OPEN_MODERN_TRANSITION_MODAL,
	OPEN_LEGACY_TRANSITION_MODAL,
} from '../../../common/constants';
import type { Actions, ACTION_AFTER_BENEFITS_MODAL } from '../../../common/types';
import { startIssueTransitionMetrics } from '../../../common/ui';

const hideBenefitsModal: Actions['hideBenefitsModal'] =
	(nextAction: ACTION_AFTER_BENEFITS_MODAL) =>
	({ setState, getState }) => {
		const currentState = getState();

		if (nextAction === OPEN_MODERN_TRANSITION_MODAL) {
			startIssueTransitionMetrics(currentState.isSubsequentLoad);

			setState({
				...currentState,
				isBenefitsModalOpen: false,
				isModalOpen: true,
			});
		} else if (nextAction === OPEN_LEGACY_TRANSITION_MODAL) {
			const { openLegacyTransitionsDialog } = getState();
			setState({
				...currentState,
				isBenefitsModalOpen: false,
				isModalOpen: false,
			});
			openLegacyTransitionsDialog?.();
		}
	};

export default hideBenefitsModal;
