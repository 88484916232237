import { useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
import type { DocumentNode } from 'graphql';
import { CARD_COVERS_TYPE, STORY_POINTS_ALIAS_FIELD_ID } from '@atlassian/jira-business-constants';
import { useCategoryField } from '@atlassian/jira-business-entity-project/src/controllers/category-field/index.tsx';
import { useFindField } from '@atlassian/jira-business-entity-project/src/controllers/find-field/index.tsx';
import type { GenericIssueField } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import { MutationError } from '@atlassian/jira-business-error-handling/src/utils/mutation-error/index.tsx';
import { GraphQLErrors } from '@atlassian/jira-business-graphql-errors';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import {
	STORY_POINT_ESTIMATE_CF_TYPE,
	NUMBER_CF_TYPE,
	SPRINT_TYPE,
	ISSUE_LINKS_TYPE,
} from '@atlassian/jira-platform-field-config';
import {
	useStartDateField,
	useIsLinkingEnabled,
} from '@atlassian/jira-router-resources-business-configuration';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import type { CreateIssueParameters, ExtraCreateIssuePayload } from '../../types';
import type { CreateIssueFragment_itemIdentifiers_issueKey_JiraSingleLineTextField as IssueKeyField } from './__generated_apollo__/CreateIssueFragment';
import { DEV_SUMMARY_FIELD, PARENT_FIELD } from './constants';
import type { MutationResponse, MutationVariables } from './types';
import { buildInput } from './utils';

type CreateIssueResponse<TIssue> = {
	item: TIssue;
	itemIdentifiers: { issueId: number; issueKey: string };
};

type CreateIssue<TIssue> = (
	createParams: CreateIssueParameters,
	extraPayload: ExtraCreateIssuePayload,
) => Promise<CreateIssueResponse<TIssue>>;

const storyPointEstimateFieldPredicate = (field: GenericIssueField) =>
	field.type === STORY_POINT_ESTIMATE_CF_TYPE;

const storyPointsFieldPredicate = (field: GenericIssueField) =>
	field.type === NUMBER_CF_TYPE && field.aliasFieldId === STORY_POINTS_ALIAS_FIELD_ID;

const sprintFieldPredicate = (field: GenericIssueField) => field.type === SPRINT_TYPE;

export const useCreateIssue = <TIssue,>(mutation: DocumentNode): CreateIssue<TIssue> => {
	const locale = useLocale();
	const { data: categoryField } = useCategoryField();
	const { data: startDateField } = useStartDateField();
	const { data: storyPointEstimateField } = useFindField(storyPointEstimateFieldPredicate);
	const { data: storyPointsField } = useFindField(storyPointsFieldPredicate);
	const { data: sprintField } = useFindField(sprintFieldPredicate);
	const isLinkingEnabled = useIsLinkingEnabled();

	const [createIssue] = useMutation<MutationResponse<TIssue>, MutationVariables>(mutation);

	return useCallback(
		async (createParams: CreateIssueParameters, extraPayload: ExtraCreateIssuePayload) => {
			try {
				const input = buildInput(
					createParams.input,
					locale,
					extraPayload,
					categoryField,
					startDateField,
					sprintField,
					storyPointEstimateField,
					storyPointsField,
				);
				const fieldIdsSet = new Set(createParams.fieldIdsToReturn);

				let isParentFieldVisible = false;
				let isDevSummaryFieldVisible = false;
				if (fg('jsw_list_view_-_all_the_fields')) {
					isDevSummaryFieldVisible = fieldIdsSet.has(DEV_SUMMARY_FIELD);
					isParentFieldVisible = fieldIdsSet.has(PARENT_FIELD);
					fieldIdsSet.delete(DEV_SUMMARY_FIELD);
					fieldIdsSet.delete(PARENT_FIELD);
				}
				const withIssueLinks = fieldIdsSet.has(ISSUE_LINKS_TYPE) && isLinkingEnabled;
				fieldIdsSet.delete(ISSUE_LINKS_TYPE);
				const withCoverMedia = fieldIdsSet.has(CARD_COVERS_TYPE);
				fieldIdsSet.delete(CARD_COVERS_TYPE);

				const fieldIds = Array.from(fieldIdsSet);

				const { data, errors } = await createIssue({
					variables: {
						input,
						fieldIds,
						withCoverMedia,
						withChildrenCount: false,
						isParentFieldVisible,
						isDevSummaryFieldVisible,
						withIssueLinks,
						withParentIssueId: fg('jsw_list_view_-_all_the_fields'),
					},
					// @ts-expect-error - errorPolicy is not in the types but does work
					errorPolicy: 'all',
				});

				const result = data?.createJiraBusinessIssue;
				if (result?.success !== true || !result?.item) {
					const firstMutationError = result?.errors?.[0];

					if (firstMutationError) {
						const statusCode = firstMutationError.extensions?.statusCode;
						if (statusCode && statusCode >= 400 && statusCode < 500) {
							throw new ValidationError(firstMutationError.message ?? 'Failed to create issue');
						}

						throw new MutationError(firstMutationError);
					}

					throw new GraphQLErrors(errors);
				}

				return {
					item: result.item,
					itemIdentifiers: {
						// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
						issueId: result.itemIdentifiers!.issueId,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-non-null-assertion
						issueKey: (result.itemIdentifiers!.issueKey![0] as IssueKeyField).text!,
					},
				};
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				if (!(error instanceof ValidationError)) {
					fireErrorAnalytics({
						meta: {
							id: 'JWMCreateIssueInline',
							packageName: 'jiraBusinessIssueCreate',
							teamName: 'wanjel',
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
					});
				}

				throw error;
			}
		},
		[
			categoryField,
			createIssue,
			locale,
			sprintField,
			startDateField,
			storyPointEstimateField,
			storyPointsField,
			isLinkingEnabled,
		],
	);
};
