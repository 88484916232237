import {
	performPutRequest,
	performDeleteRequest,
} from '@atlassian/jira-fetch/src/utils/requests.tsx';
import type { UserPreferences } from '@atlassian/jira-issue-shared-types/src/common/types/user-preferences-type.tsx';
import type { Value } from './types';

export const saveUserPreferenceValue = async (
	baseUrl: string,
	userPreferenceKey: UserPreferences,
	value: Value,
) =>
	performPutRequest(`${baseUrl}/rest/api/2/mypreferences?key=${userPreferenceKey}`, {
		body: value,
	});

export const removeUserPreferenceValue = async (
	baseUrl: string,
	userPreferenceKey: UserPreferences,
) => performDeleteRequest(`${baseUrl}/rest/api/2/mypreferences?key=${userPreferenceKey}`);
