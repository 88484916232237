import get from 'lodash/get';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { RetryLink } from 'apollo-link-retry';
import { fragmentCacheRedirect, fragmentLinkState } from 'apollo-link-state-fragment';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { UFOLoggerLink } from '@atlassian/ufo-apollo-log/link';
import introspectionQueryResultData from './common/graphql/fragment-types.json';
import { getErrorAnalyticsContext, reportErrors } from './common/utils';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports, jira/restricted/graphql-tag
export { default as gqlTagGira } from 'graphql-tag';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { withErrorAnalyticsContext } from './common/utils';

const GIRA_APOLLO_CLIENT_ID = 'gira';
const CACHE_NAME = `apollo-cache/${GIRA_APOLLO_CLIENT_ID}`;
const LOG_NAMESPACE = `apollo.${GIRA_APOLLO_CLIENT_ID}.error.graphql`;

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const isSsrSuccessful = () => (__SERVER__ ? true : !window || !window.SPA_STATE);
const NETWORK_ERRORS_COUNTER_KEY = 'networkErrorsCounter';

// Exporting fragmentMatcher to use it in StoryBook examples
export const fragmentMatcher = new IntrospectionFragmentMatcher({
	introspectionQueryResultData,
});

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
	// Ignore network errors as they will be logged in the retry link
	reportErrors({ graphQLErrors, operation });

	// Skip default error logging if analytics context data is defined for the query.
	const errorAnalytics = getErrorAnalyticsContext(operation.getContext());
	if (errorAnalytics !== undefined) {
		return;
	}

	if (graphQLErrors)
		if (Array.isArray(graphQLErrors)) {
			graphQLErrors.map(({ message, locations, path }) =>
				log.safeErrorWithoutCustomerData(
					LOG_NAMESPACE,
					`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
					{
						message: JSON.stringify(message),
						path: JSON.stringify(path),
						locations: JSON.stringify(locations),
					},
				),
			);
		} else {
			log.safeErrorWithoutCustomerData(
				LOG_NAMESPACE,
				'[GraphQL error]: Unexpected error message format',
				{
					message: JSON.stringify(graphQLErrors),
				},
			);
		}

	if (networkError) {
		const occuredNetworkErrorsBefore = get(operation.getContext(), [NETWORK_ERRORS_COUNTER_KEY], 0);
		log.safeErrorWithoutCustomerData(
			LOG_NAMESPACE,
			`[Network error]: ${networkError}, attempt ${occuredNetworkErrorsBefore + 1}`,
		);
		operation.setContext({
			[NETWORK_ERRORS_COUNTER_KEY]: occuredNetworkErrorsBefore + 1,
		});
	}
});

const retryLinkWithFama = new RetryLink({
	delay: {
		initial: 300,
		max: 120000,
		jitter: true,
	},
	attempts: (count, operation, networkError) => {
		const retry = count < 5 && networkError && !networkError.statusCode;

		// Don't report any error if we're going to attempt a retry
		if (!retry) {
			reportErrors({ networkError, operation });
		}

		return retry;
	},
});

class CancelableRequestLink extends ApolloLink {
	inFlightRequestControllers: {
		[key: string]: AbortController | undefined;
	};

	constructor() {
		super();
		this.inFlightRequestControllers = {};
	}

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	request(operation: any, forward: any) {
		const context = operation.getContext();
		if (!context.cancelable) {
			return forward(operation);
		}

		const key = JSON.stringify([operation.operationName, operation.query]); // Equivalent to `operation.toKey` less the _actual_ variables

		this.inFlightRequestControllers[key]?.abort(); // Always abort the previous request

		const abortController = new AbortController();
		operation.setContext({
			...context,
			fetchOptions: { signal: abortController.signal }, // HttpLink passes this directly to fetch implementation
		});
		this.inFlightRequestControllers[key] = abortController;

		const cleanup = () => {
			// This callback may be called long after the request is replaced
			if (this.inFlightRequestControllers[key] === abortController) {
				delete this.inFlightRequestControllers[key];
			}
		};

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return new Observable((observer: any) => {
			const subscription = forward(operation).subscribe({
				next: observer.next.bind(observer),
				// @ts-expect-error - TS7006 - Parameter 'error' implicitly has an 'any' type.
				error: (error) => {
					observer.error(error);
					cleanup();
				},
				complete: observer.complete.bind(observer),
			});

			return () => {
				subscription?.unsubscribe();
				cleanup();
			};
		});
	}
}

const cache = new InMemoryCache({
	cacheRedirects: {
		Query: {
			...fragmentCacheRedirect(),
		},
	},
	fragmentMatcher,
	dataIdFromObject: (object) => object.id && `${object.id}${object.__typename}`,
})
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	.restore((!isSsrSuccessful() && window.SPA_STATE[CACHE_NAME]) || {});

export const clientCreator = (baseUrl: string) =>
	new ApolloClient({
		name: GIRA_APOLLO_CLIENT_ID,
		ssrMode: isSsrSuccessful(),
		// @ts-expect-error - TS2345 - Argument of type '{ name: string; ssrMode: boolean; addTypename: boolean; link: ApolloLink; cache: InMemoryCache; connectToDevTools: boolean; }' is not assignable to parameter of type 'ApolloClientOptions<NormalizedCacheObject>'.
		addTypename: true,
		link: ApolloLink.from(
			[
				retryLinkWithFama,
				errorLink,
				UFOLoggerLink,
				fragmentLinkState(cache),
				...(!isSsrSuccessful() ? [new CancelableRequestLink()] : []),
				new HttpLink({
					uri: ({ operationName }) =>
						`${baseUrl}/rest/${GIRA_APOLLO_CLIENT_ID}/1/?operation=${encodeURIComponent(
							operationName,
						)}`,
					credentials: 'same-origin',
					// @ts-expect-error - TS2345 - Argument of type '{ uri: ({ operationName }: Operation) => string; credentials: string; abortErrorDoesNotCauseError: boolean; }' is not assignable to parameter of type 'Options'.
					abortErrorDoesNotCauseError: !isSsrSuccessful(),
				}),
			].filter(Boolean),
		),
		cache,
		connectToDevTools: process.env.NODE_ENV === 'development',
	});

export default {
	clientId: GIRA_APOLLO_CLIENT_ID,
	clientCreator,
} as const;
