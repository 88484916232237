import gqlTagGira from 'graphql-tag';

export const JIS2_JWMBOARD_CONFLUENCE_LINKS_FRAGMENT = gqlTagGira`
fragment JIS2_JWMBoardConfluenceLinksFragment on JiraWorkManagementViewItem {
  legacyConfluenceLinks (getFirstOnly: true) {
    linkedConfluencePages {
      pageCount
    }
  }
}
`;

export const JIS2_JWMBOARD_ISSUE_LINKS_FRAGMENT = gqlTagGira`
fragment JIS2_JWMBoardIssueLinksFragment on JiraWorkManagementViewItem {
  issueLinks(first: 10) {
    edges {
      node {
        issue {
          issueId
        }
      }
    }
  }
}
`;

const JIS2_JWMBOARD_ACCOUNT_FRAGMENT = gqlTagGira`
fragment JIS2_JWMBoardAccountFragment on AtlassianAccountUser {
  accountId
  name
  picture
}
`;

const JIS2_JWMBOARD_USER_FRAGMENT = gqlTagGira`
fragment JIS2_JWMBoardUserFragment on JiraUserConnection {
  edges {
    node {
      ...JIS2_JWMBoardAccountFragment
    }
  }
}
${JIS2_JWMBOARD_ACCOUNT_FRAGMENT}
`;

export const JIS2_JWMBOARD_ITEM_COVER_MEDIA_FRAGMENT = gqlTagGira`
fragment JIS2_JWMBoardItemCoverMediaFragment on JiraWorkManagementViewItem {
  coverMedia {
    ... on JiraWorkManagementColorBackground {
      entityId
      colorValue
    }
    ... on JiraWorkManagementGradientBackground {
      entityId
      gradientValue
    }
    ... on JiraWorkManagementAttachmentBackground {
        entityId
        attachment {
            attachmentId
            mediaReadToken(durationInSeconds: 900, maxTokenLength: 65534)
            fileName
            mediaApiFileId
            __typename
            # Helpful for determining the source of the attachment, such as Unsplash so that we can attribute the source
            ... on JiraPlatformAttachment {
                sourceType: attachmentProperty(key: "sourceType")
                sourceIdentifier: attachmentProperty(key: "sourceIdentifier")
            }
        }
    }
  }
}`;

export const JIS2_JWMBOARD_ITEM_FRAGMENT = gqlTagGira`
fragment JIS2_JWMBoardItemFragment on JiraWorkManagementViewItem {
  id
  issueId
  fieldsByIdOrAlias(idsOrAliases: $fieldIds, ignoreMissingFields: true) {
    __typename
    fieldId
    name
    ... on JiraSingleLineTextField {
      text
      type
    }
    ... on JiraLabelsField {
      selectedLabelsConnection {
        edges {
          node {
            name,
          }
        }
      }
    }
    ... on JiraSingleSelectUserPickerField {
      user {
        ... JIS2_JWMBoardAccountFragment
      }
    }
    ... on JiraDatePickerField {
      date
    }
    ... on JiraDateTimePickerField {
      dateTime
    }
    ... on JiraComponentsField {
      selectedComponentsConnection {
        edges {
          node {
            name
          }
        }
      }
    }
    ... on JiraOriginalTimeEstimateField {
      originalEstimate {
        timeInSeconds
      }
    }
    ... on JiraSingleSelectField {
      type
      fieldOption {
        optionId
        value
        color {
          colorKey
        }
      }
    }
    ... on JiraMultipleSelectField {
      selectedOptions {
        edges {
          node {
            value
          }
        }
      }
    }
    ... on JiraNumberField {
      number
    }
    ... on JiraMultipleSelectUserPickerField {
      selectedUsersConnection {
        ...JIS2_JWMBoardUserFragment
      }
    }
    ... on JiraPeopleField {
      selectedUsersConnection {
        ... JIS2_JWMBoardUserFragment
      }
    }
    ... on JiraTimeTrackingField {
      originalEstimate {
        timeInSeconds
      }
      timeTrackingSettings {
        defaultUnit
        defaultFormat
        workingHoursPerDay
        workingDaysPerWeek
      }
    }
    ... on JiraStatusField {
      status {
        name
        statusId
        statusCategory {
          statusCategoryId
        }
      }
    }
    ... on JiraIssueTypeField {
      issueType {
        issueTypeId
        name
        avatar {
          medium
        }
        hierarchy {
          level
        }
      }
    }
    ... on JiraPriorityField {
      priority {
        iconUrl
        name
        priorityId
      }
    }
    ... on JiraParentIssueField {
      parentIssue {
        issueId
        key
        summaryField {
          text
        }
      }
    }
  },
  childrenTotal: childSummary(includeDone:true) @include(if: $withChildrenCount) {
    totalCount,
  }
  childrenNotDone: childSummary(includeDone:false) @include(if: $withChildrenCount) {
    totalCount,
  }
}
${JIS2_JWMBOARD_ACCOUNT_FRAGMENT}
${JIS2_JWMBOARD_USER_FRAGMENT}
`;

export const JIS2_JWMBoardItemsQuery = gqlTagGira`
query JIS2JWMBoardItems($cloudId: ID!, $jql: String!, $fieldIds: [String]!, $first: Int, $after: String, $withConfluenceLinks: Boolean!, $withCoverMedia: Boolean!, $withIssueLinks: Boolean!, $withChildrenCount: Boolean!) {
  jira {
    jwmViewItems(cloudId: $cloudId, jql: $jql, first: $first, after: $after) @connection (key: "boardJIS", filter:["jql"]) {
      __typename
      ... on QueryError {
          message
          extensions {
              statusCode
              errorType
          }
      }
      ... on JiraWorkManagementViewItemConnection {
        edges {
          node {
            ...JIS2_JWMBoardItemFragment
            ...JIS2_JWMBoardConfluenceLinksFragment @include(if: $withConfluenceLinks)
            ...JIS2_JWMBoardItemCoverMediaFragment @include(if: $withCoverMedia)
            ...JIS2_JWMBoardIssueLinksFragment @include(if: $withIssueLinks)
          }
        }
      }
    }
  }
}
${JIS2_JWMBOARD_ITEM_FRAGMENT}
${JIS2_JWMBOARD_CONFLUENCE_LINKS_FRAGMENT}
${JIS2_JWMBOARD_ITEM_COVER_MEDIA_FRAGMENT}
${JIS2_JWMBOARD_ISSUE_LINKS_FRAGMENT}
`;
