import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { isHttpClientErrorResponse } from '@atlassian/jira-fetch/src/utils/is-error.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { INITIAL_DATA_QUERY } from './gql';
import type { Response, InitialQueryVariables } from './types';

const MAX_NUMBER_OF_OPTIONS = 20; // min number to render two rows

export const fetchGroupItemValues = async (
	signal: AbortSignal,
	groupFieldId: string,
	jqlContext: string,
): Promise<Response> => {
	try {
		const variables: InitialQueryVariables = {
			fieldId: groupFieldId,
			first: MAX_NUMBER_OF_OPTIONS,
			jql: jqlContext,
		};

		const response = await performPostRequest<Response>(
			'/rest/gira/1/?operation=JWMFilterValuesByGroupItems',
			{
				body: JSON.stringify({
					query: INITIAL_DATA_QUERY,
					variables,
				}),
			},
		);

		return response;
	} catch (error: unknown) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		if (!isHttpClientErrorResponse(error as Error) && !signal.aborted) {
			fireErrorAnalytics({
				meta: {
					id: 'initializeFilterGroupItemsData',
					packageName: 'jiraBusinessFilters',
					teamName: 'wanjel',
				},
				attributes: {
					message: 'Failed to initialize filter data using the group query',
				},
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				error: error as Error,
				sendToPrivacyUnsafeSplunk: true,
			});
		}

		throw error;
	}
};
