import { useCallback } from 'react';
import { useProject } from '@atlassian/jira-business-entity-project-hook';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import {
	useExperienceAbort,
	useExperienceStart,
	useExperienceFail,
	useExperienceSuccess,
} from '@atlassian/jira-business-experience-tracking/src/controllers/experience-tracker/index.tsx';
import type { ExperienceDetails } from '@atlassian/jira-business-experience-tracking/src/types.tsx';
import { useWorkflowsV2 } from '@atlassian/jira-business-workflows/src/services/workflow-v2';
import type { StatusCategory } from '@atlassian/jira-common-constants/src/status-categories';
import { useFlagService } from '@atlassian/jira-flags';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { COLUMN_ACTION_ADD_EXPERIENCE, TEMPORARY_STATUS_ID } from '../../../common/constants';
import { useBoardData } from '../../board-data';
import { useWorkflowStoreActions } from '../../workflow-store';
import { useStartAddOperation, useStopWorkflowOperationInProgress } from '../index';
import {
	getStatusActionFailedFlag,
	failStatusExperience,
	IssueTypeIdNotFoundError,
} from '../utils';
import messages from './messages';

export const useAddColumn = () => {
	const { removeColumn } = useWorkflowStoreActions();

	const cloudId = useCloudId();
	const { createWorkflowStatusV2 } = useWorkflowsV2();

	const startAddOperation = useStartAddOperation();
	const stopWorkflowOperationInProgress = useStopWorkflowOperationInProgress();

	const {
		data: { issueTypes },
	} = useIssueTypesAndFields({
		issueOperation: 'VIEW',
	});

	const startExperience = useExperienceStart(COLUMN_ACTION_ADD_EXPERIENCE);
	const markExperienceSuccess = useExperienceSuccess(COLUMN_ACTION_ADD_EXPERIENCE);
	const markExperienceFailed = useExperienceFail(COLUMN_ACTION_ADD_EXPERIENCE);
	const abortExperience = useExperienceAbort(COLUMN_ACTION_ADD_EXPERIENCE);

	const projectData = useProject();
	const projectId = String(projectData.id);
	const projectKey = projectData.key;

	const { refetch: refetchBoardData } = useBoardData();
	const { showFlag } = useFlagService();

	const addNewColumn = useCallback(
		async ({
			statusName,
			statusCategory,
		}: {
			statusName: string;
			statusCategory: StatusCategory;
		}) => {
			startAddOperation(String(TEMPORARY_STATUS_ID));
			startExperience();

			try {
				await createWorkflowStatusV2({
					cloudId,
					projectId,
					issueTypes,
					statusName,
					statusCategory,
					onSuccess: markExperienceSuccess,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				removeColumn(TEMPORARY_STATUS_ID);

				const issueTypeIdNotSubtask = issueTypes.find(
					(issueType) => issueType.hierarchyLevel === 0,
				)?.id;

				showFlag(
					getStatusActionFailedFlag({
						messageTitle: messages.errorFlagTitleAddColumn,
						action: 'add',
						error,
						issueTypeIdNotSubtask,
						projectKey,
					}),
				);

				failStatusExperience({
					markExperienceFailed,
					abortExperience,
					error,
					errorMessage: 'Failed to validate and update workflow',
				});

				throw error;
			} finally {
				stopWorkflowOperationInProgress();
				refetchBoardData();
			}
		},
		[
			startAddOperation,
			startExperience,
			createWorkflowStatusV2,
			cloudId,
			projectId,
			issueTypes,
			markExperienceSuccess,
			removeColumn,
			showFlag,
			projectKey,
			markExperienceFailed,
			abortExperience,
			stopWorkflowOperationInProgress,
			refetchBoardData,
		],
	);

	return addNewColumn;
};

export const useCreateStatus = (experienceDetails: ExperienceDetails) => {
	const cloudId = useCloudId();
	const { createWorkflowStatusforSingleWorkflow } = useWorkflowsV2();

	const startAddOperation = useStartAddOperation();
	const stopWorkflowOperationInProgress = useStopWorkflowOperationInProgress();

	const startExperience = useExperienceStart(experienceDetails);
	const markExperienceSuccess = useExperienceSuccess(experienceDetails);
	const markExperienceFailed = useExperienceFail(experienceDetails);
	const abortExperience = useExperienceAbort(experienceDetails);

	const project = useProject();
	const { showFlag } = useFlagService();

	const createNewStatus = useCallback(
		async ({
			statusName,
			statusCategory,
			issueTypeIdNotSubtask,
		}: {
			statusName: string;
			statusCategory: StatusCategory;
			issueTypeIdNotSubtask: string | undefined;
		}) => {
			startAddOperation(String(TEMPORARY_STATUS_ID));
			startExperience();

			try {
				if (issueTypeIdNotSubtask == null) {
					throw new IssueTypeIdNotFoundError(
						'issueTypeIdNotSubtask is required but was not found.',
					);
				}
				await createWorkflowStatusforSingleWorkflow({
					statusName,
					statusCategory,
					cloudId,
					projectId: String(project.id),
					issueTypeId: issueTypeIdNotSubtask,
					onSuccess: markExperienceSuccess,
				});
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (error: any) {
				showFlag(
					getStatusActionFailedFlag({
						messageTitle: messages.errorFlagTitleAddColumn,
						action: 'add',
						error,
						issueTypeIdNotSubtask,
						projectKey: project.key,
						statusCode: error?.response?.status,
					}),
				);

				failStatusExperience({
					markExperienceFailed,
					abortExperience,
					error,
					errorMessage: 'Failed to validate and update workflow',
				});

				throw error;
			} finally {
				stopWorkflowOperationInProgress();
			}
		},
		[
			startAddOperation,
			startExperience,
			createWorkflowStatusforSingleWorkflow,
			cloudId,
			markExperienceSuccess,
			showFlag,
			markExperienceFailed,
			abortExperience,
			stopWorkflowOperationInProgress,
			project,
		],
	);

	return createNewStatus;
};
