import noop from 'lodash/noop';
import TransitionScreenLegacyDialog from '@atlassian/jira-common-components-transition-screen-legacy-dialog';
import { timeToRenderTransitionModal } from '@atlassian/jira-common-components-transition-screen-legacy-dialog/src/transition-modal-performance-analytics';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { IssueTransitionPayloadCallbacks } from '../../common/types';

export const openLegacyIssueTransitionModal = ({
	issueKey,
	transitionId,
	onDialogSuccess,
	onDialogCancel,
	onDialogError,
	isReopenScenario = false,
	onOpenDialog,
}: {
	issueKey: string;
	transitionId: string;
	isReopenScenario?: boolean;
	onOpenDialog?: () => void;
} & IssueTransitionPayloadCallbacks) => {
	try {
		timeToRenderTransitionModal.start();
		new TransitionScreenLegacyDialog({
			issueKey,
			transitionId,
		})
			.onDialogSuccess(onDialogSuccess || noop)
			.onDialogCancel(onDialogCancel || noop)
			.onDialogError(onDialogError || noop)
			.show();
		onOpenDialog?.();
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'transitionIssueTrigger',
				packageName: 'jiraIssueTransitionTrigger',
				teamName: 'gryffindor',
			},
			error,
			attributes: {
				transitionModalVersion: 'OLD',
				isReopenScenario,
			},
			sendToPrivacyUnsafeSplunk: true,
		});
		throw error;
	}
};
